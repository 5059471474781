import {Component, OnDestroy, OnInit} from '@angular/core';
import {Widget} from "../widget";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {Factura} from "../../../model/documentacion/factura.model";
import {FacturasService} from "../../../services/facturas.service";

@Component({
    selector: 'app-ultimas-facturas',
    templateUrl: './ultimas-facturas-widget.component.html',
    styleUrls: ['./ultimas-facturas-widget.component.scss']
})
export class UltimasFacturasWidgetComponent implements Widget, OnInit, OnDestroy {
    public static minCols = 4;
    public static minRows = 2;
    public static maxRows = 2;
    public static maxCols = 4;
    public static widgetName = 'Últimas facturas disponibles';
    public static widgetKey = 'ultimas-facturas';
    facturas: Factura[] = [];
    cargando: boolean = false;

    constructor(private interfaceProvider: InterfaceProviderService, private facturaService: FacturasService) {
        interfaceProvider.managerForModel(Factura).all.subscribe(facturas => {
            this.facturas = facturas.slice(0, 5);
        })
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    descargar(factura: Factura) {
        this.cargando = true;
        this.facturaService.finalizadoEmitter.subscribe(finalizado => {
            this.cargando = !finalizado;
        })
        this.facturaService.imprimir(factura);
    }
}

