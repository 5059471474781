import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {
    ManagerCachingStrategy,
    PropertyMap,
    SearchType
} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Plantilla de Acciones de Incidencias',
    endpoint: 'incidencia_accion_plantillas',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'descripcion',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'IncidenciaAccionPlantilla'
})
export class IncidenciaAccionPlantilla extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Descripción de la incidencia',
        type: PropertyType.String(),
        nullable: true
    })
    private _descripcion;

    public static readonly MAP_INCIDENCIA_PLANTILLA: PropertyMap = {
        name: 'Plantilla de incidencia',
        type: PropertyType.FutureUri('incidencia-plantilla.model.ts')
    }
    @BasicProperty(IncidenciaAccionPlantilla.MAP_INCIDENCIA_PLANTILLA)
    private _incidenciaPlantilla;

    @BasicProperty({
        name: 'Acción a realizar',
        type: PropertyType.String(),
        array: true
    })
    private _claseAccion;


    @BasicProperty({
        name: 'Campos adicionales',
        type: PropertyType.Object(),
        array: true
    })
    private _camposAdicionales = [];

    @BasicProperty({
        name: 'Ejecución automática',
        type: PropertyType.Boolean()
    })
    private _ejecucionAutomatica = true;

    @BasicProperty({
        name: 'Ejecutar una sola vez',
        type: PropertyType.Boolean()
    })
    private _ejecutarUnaSolaVez = true;

    @BasicProperty({
        name: 'Datos',
        type: PropertyType.Text(),
        nullable: true,
        array: true
    })
    private _data;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }

    get incidenciaPlantilla() {
        return this._incidenciaPlantilla;
    }

    set incidenciaPlantilla(value) {
        this._incidenciaPlantilla = value;
    }

    get claseAccion() {
        return this._claseAccion;
    }

    set claseAccion(value) {
        this._claseAccion = value;
    }

    get ejecucionAutomatica(): boolean {
        return this._ejecucionAutomatica;
    }

    set ejecucionAutomatica(value: boolean) {
        this._ejecucionAutomatica = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }

    get camposAdicionales() {
        return this._camposAdicionales;
    }

    set camposAdicionales(value) {
        this._camposAdicionales = value;
    }

    get ejecutarUnaSolaVez(): boolean {
        return this._ejecutarUnaSolaVez;
    }

    set ejecutarUnaSolaVez(value: boolean) {
        this._ejecutarUnaSolaVez = value;
    }
}
