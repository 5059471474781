import {BasicEntity} from "../../basic-entity-back/annotations";
import {Solicitud} from "./solicitud.model";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Solicitud de Escaneo',
    endpoint: 'solicitud_escaneos',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    type: 'SolicitudEscaneo',
    managerCaching: ManagerCachingStrategy.Lazy
})

export class SolicitudEscaneo extends Solicitud {

}
