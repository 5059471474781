import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Tarifa} from "./tarifa.model";
import {Opcion} from "./opcion.model";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";

@BasicEntity({
    name: "Opciones de tarifa",
    endpoint: "tarifa_opcions",
    isPaginated: false,
    modelNameProperty: "resumen",
    type: "TarifaOpcion"
})
export class TarifaOpcion extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    public static TARIFA_MAP: PropertyMap = {
        name: "Tarifa",
        type: PropertyType.FutureUri('tarifa.model.ts')
    }
    @BasicProperty(TarifaOpcion.TARIFA_MAP)
    private _tarifa: Tarifa;

    @BasicProperty({
        name: 'Opción',
        type: PropertyType.NestedModel(Opcion, null, NestedBehavior.SendIriOnly)
    })
    private _opcion: Opcion;

    @BasicProperty({
        name: 'Precio',
        type: PropertyType.Financial()
    })
    private _precioAditivo: number;

    @BasicProperty({
        name: 'Unidades mínimas',
        type: PropertyType.Integer()
    })
    private _unidadesMinimas = 0;

    get resumen(): string {
        return `${this.opcion.descripcion} (+${this.precioAditivo} €)` + (this.unidadesMinimas > 0 ? ' - Mínimo ' + this.unidadesMinimas + ' uds' : '')
    }

    get nombre(): string {
        return `${this.opcion.descripcion}`
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get tarifa(): Tarifa {
        return this._tarifa;
    }

    set tarifa(value: Tarifa) {
        this._tarifa = value;
    }

    get opcion(): Opcion {
        return this._opcion;
    }

    set opcion(value: Opcion) {
        this._opcion = value;
    }

    get precioAditivo(): number {
        return this._precioAditivo;
    }

    set precioAditivo(value: number) {
        this._precioAditivo = value;
    }

    get unidadesMinimas(): number {
        return this._unidadesMinimas;
    }

    set unidadesMinimas(value: number) {
        this._unidadesMinimas = value;
    }
}
