import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Tarifa} from "./tarifa.model";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: "Tarifas de acabados",
    endpoint: "tarifa_tipo_materials",
    isPaginated: false,
    modelNameProperty: "resumen",
    type: "TarifaTipoMaterial"
})
export class TarifaTipoMaterial extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    public static TARIFA_MAP: PropertyMap = {
        name: "Tarifa",
        type: PropertyType.FutureUri('tarifa.model.ts')
    }
    @BasicProperty(TarifaTipoMaterial.TARIFA_MAP)
    private _tarifa: Tarifa;

    @BasicProperty({
        name: 'Precio aditivo',
        type: PropertyType.Financial()
    })
    private _precioAditivo: number;

    @BasicProperty({
        name: 'Unidades mínimas',
        type: PropertyType.Integer()
    })
    private _unidadesMinimas = 0;

    @BasicProperty({
        name: 'Unidades máximas',
        type: PropertyType.Integer()
    })
    private _unidadesMaximas = 0;

    get resumen(): string {
        return `+${this.precioAditivo} €` + (' - De ' + this.unidadesMinimas) + (' a ' + this.unidadesMaximas + ' uds')
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get tarifa(): Tarifa {
        return this._tarifa;
    }

    set tarifa(value: Tarifa) {
        this._tarifa = value;
    }

    get unidadesMaximas(): number {
        return this._unidadesMaximas;
    }

    set unidadesMaximas(value: number) {
        this._unidadesMaximas = value;
    }

    get precioAditivo(): number {
        return this._precioAditivo;
    }

    set precioAditivo(value: number) {
        this._precioAditivo = value;
    }

    get unidadesMinimas(): number {
        return this._unidadesMinimas;
    }

    set unidadesMinimas(value: number) {
        this._unidadesMinimas = value;
    }
}
