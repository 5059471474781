import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocalizacionModule} from './pages/localizacion/localizacion.module';
import {PersonasModule} from './pages/personas/personas.module';
import {AdminModule} from './pages/admin/admin.module';
import {ShellModule} from './shell/shell.module';
import {SessionModule} from './session/session.module';
import {InicioComponent} from './pages/inicio/inicio.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PortalModule} from '@angular/cdk/portal';
import {BasicEntityFrontModule} from './basic-entity-front/basic-entity-front.module';
import {TABLES} from './tables';
import {INTERFACE_PROVIDER_PATHS} from './basic-entity-back/services/interface-provider.service';
import {TestWidget2Component} from './pages/inicio/test-widget2/test-widget2.component';


import {MAT_DATE_LOCALE} from '@angular/material/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoadingInterceptor} from './shell/loading/loading.interceptor';
import {DialogSuccessComponent} from "./pages/dialogoSuccess/dialogSuccess.component";
import {DialogConfirmComponent} from "./pages/dialogoConfirm/dialogConfirm.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSortModule} from "@angular/material/sort";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatFileUploadModule} from "angular-material-fileupload";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatListModule} from "@angular/material/list";
import {MatExpansionModule} from "@angular/material/expansion";
import {WelcomeComponent} from './pages/inicio/welcome/welcome.component';
import {PasswordResetComponent} from './session/password-reset/password-reset.component';
import {TornillosComponent} from './pages/almacen/tornillos/tornillos.component';
import {
    PiezasProduccionWidgetComponent
} from "./pages/inicio/piezas-produccion-widget/piezas-produccion-widget.component";
import {
    SolicitudesPendientesValidarWidgetComponent
} from './pages/inicio/solicitudes-pendientes-validar-widget/solicitudes-pendientes-validar-widget.component';
import {
    SolicitudesValidadasWidgetComponent
} from './pages/inicio/solicitudes-validadas-widget/solicitudes-validadas-widget.component';
import {UltimasFacturasWidgetComponent} from "./pages/inicio/ultimas-facturas/ultimas-facturas-widget.component";
import {
    PiezasEnTransitoWidgetComponent
} from './pages/inicio/piezas-en-transito-widget/piezas-en-transito-widget.component';
import {
    SolicitudesRechazadasWidgetComponent
} from './pages/inicio/solicitudes-rechazadas-widget/solicitudes-rechazadas-widget.component';
import {IncidenciasWidgetComponent} from './pages/inicio/incidencias-widget/incidencias-widget.component';
import {VisorStlComponent} from "./pages/visor-stl/visor-stl.component";
import {
    PedidosRechazadosWidgetComponent
} from "./pages/inicio/pedidos-rechazados-widget/pedidos-rechazados-widget.component";

@NgModule({
    declarations: [
        AppComponent,
        InicioComponent,
        PiezasProduccionWidgetComponent,
        TestWidget2Component,
        DialogSuccessComponent,
        DialogConfirmComponent,
        WelcomeComponent,
        PasswordResetComponent,
        TornillosComponent,
        SolicitudesPendientesValidarWidgetComponent,
        SolicitudesValidadasWidgetComponent,
        UltimasFacturasWidgetComponent,
        PiezasEnTransitoWidgetComponent,
        SolicitudesRechazadasWidgetComponent,
        PedidosRechazadosWidgetComponent,
        IncidenciasWidgetComponent,
        VisorStlComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LocalizacionModule,
        PersonasModule,
        AdminModule,
        ShellModule,
        SessionModule,
        FormsModule,
        ReactiveFormsModule,
        PortalModule,
        BasicEntityFrontModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        MatSortModule,
        MatIconModule,
        MatSelectModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSidenavModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatFileUploadModule,
        MatProgressBarModule,
        MatListModule,
        MatExpansionModule,
    ],
    providers: [
        {provide: INTERFACE_PROVIDER_PATHS, useValue: TABLES},
        {provide: MAT_DATE_LOCALE, useValue: 'es'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    exports: [MatDatepickerModule],
    bootstrap: [AppComponent]
})
export class AppModule {
}
