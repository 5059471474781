import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy, SearchType} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Empleado} from "../personas/empleado.model";
import {UserGroup} from "../personas/user-group.model";
import {IncidenciaAccion} from "./incidencia-accion.model";
import {Incidencia} from "./incidencia.model";
import {IncidenciaAccionPlantilla} from "./incidencia-accion-plantilla.model";

@BasicEntity({
    name: 'Plantilla de Incidencias',
    endpoint: 'incidencia_plantillas',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'descripcion',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'IncidenciaPlantilla'
})
export class IncidenciaPlantilla extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Nombre del tipo de incidencia',
        type: PropertyType.String(),
    })
    private _nombre;

    @BasicProperty({
        name: 'Descripción del tipo de incidencia',
        type: PropertyType.String()
    })
    private _descripcion;

    @BasicProperty({
        name: 'Departamento afectado',
        type: PropertyType.NestedModel(UserGroup, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _departamentoAfectado;

    @BasicProperty({
        name: 'Plantilla de acción',
        type: PropertyType.NestedModel(IncidenciaAccionPlantilla, 'incidenciaPlantilla', NestedBehavior.SendIriOnly),
        array: true
    })
    private _accionesPlantilla;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get nombre() {
        return this._nombre;
    }

    set nombre(value) {
        this._nombre = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }

    get departamentoAfectado() {
        return this._departamentoAfectado;
    }

    set departamentoAfectado(value) {
        this._departamentoAfectado = value;
    }

    get accionesPlantilla() {
        return this._accionesPlantilla;
    }

    set accionesPlantilla(value) {
        this._accionesPlantilla = value;
    }
}

IncidenciaAccionPlantilla.MAP_INCIDENCIA_PLANTILLA.type = PropertyType.Uri(IncidenciaPlantilla);
