import {Resource} from "../../api/resource";
import {BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";

export class Archivo extends Resource {
    public static readonly TIPOS = {
        STL: 'stl',
        ZIP: 'zip',
        PLY: 'ply',
        OBJ: 'obj',
        JPG: 'jpg',
        PNG: 'png'
    }
    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Tipo',
        type: PropertyType.Choice(Archivo.TIPOS),
        readWrite: ReadWrite.ReadOnly
    })
    private _tipo = Archivo.TIPOS.STL;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _nombreFichero: string;

    @BasicProperty({
        name: 'URL completa del archivo',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _url: string;

    @BasicProperty({
        name: 'Nombre del archivo',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _filePath: string;

    @BasicProperty({
        name: 'Creado por',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _creadoPor;

    @BasicProperty({
        name: 'Visible',
        type: PropertyType.Boolean(),
        dontReadFromApi: true
    })
    private _visible;


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get tipo(): string {
        return this._tipo;
    }

    set tipo(value: string) {
        this._tipo = value;
    }

    get nombreFichero(): string {
        return this._nombreFichero;
    }

    set nombreFichero(value: string) {
        this._nombreFichero = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    get filePath(): string {
        return this._filePath;
    }

    set filePath(value: string) {
        this._filePath = value;
    }

    get creadoPor() {
        return this._creadoPor;
    }

    set creadoPor(value) {
        this._creadoPor = value;
    }

    get visible() {
        return this._visible;
    }

    set visible(value) {
        this._visible = value;
    }

}
