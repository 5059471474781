import {BaseEntity} from "../base-entity.model";
import {BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {Cliente} from "../personas/cliente.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {Material} from "../materiales/material.model";
import {Pedido} from "../pedidos/pedido.model";
import {LineaSolicitud} from "./linea-solicitud.model";
import {ArchivoImagenSolicitud} from "../archivos/archivo-imagen-solicitud.model";
import {ArchivoSTLSolicitud} from "../archivos/archivo-stl-solicitud.model";
import {ComentarioRevision} from "./comentario-revision.model";

export abstract class Solicitud extends BaseEntity {
    static readonly ESTADOS_SOLICITUD = {
        // PENDIENTE: 'Pendiente',
        // ENVIADO: 'Enviado',
        // EN_CURSO: 'En curso',
        // CONFIRMADO: 'Pendiente de diseñar',
        // RECIBIDO: 'Recibido',
        // FINALIZADO: 'Diseño validado',
        // PENDIENTE_VALIDAR: 'Pendiente de Validar',
        // A_REVISAR: 'A Revisar',
        // CANCELADO: 'Cancelado'
        EN_CURSO: "Solicitud en curso",
        REALIZADO: "Solicitud realizada",
        RECHAZADO: "Solicitud rechazada",
        PENDIENTE_VALIDAR: "Pendiente de validación",
        A_REVISAR: "Diseño en revisión",
        VALIDADO: "Diseño validado"
    };

    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Cliente',
        type: PropertyType.NestedModel(Cliente, null, NestedBehavior.SendIriOnly),
        filters: [ExactSearchFilter]
    })
    private _cliente: Cliente;

    @BasicProperty({
        name: 'Pedido',
        type: PropertyType.NestedModel(Pedido, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _pedido: Pedido;

    @BasicProperty({
        name: 'Líneas',
        keyInApi: 'lineasSolicitud',
        type: PropertyType.NestedModel(LineaSolicitud, 'solicitud', NestedBehavior.CreateOnly),
        array: true
    })
    private _lineas: LineaSolicitud[] = [];

    @BasicProperty({
        name: 'Imagen',
        type: PropertyType.NestedModel(ArchivoImagenSolicitud, 'solicitud', NestedBehavior.SendIriOnly),
        array: true
    })
    private _imagenes: ArchivoImagenSolicitud[] = [];

    @BasicProperty({
        name: 'Archivo',
        type: PropertyType.NestedModel(ArchivoSTLSolicitud, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _stlSolicitud;

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(Solicitud.ESTADOS_SOLICITUD)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _estado;

    @BasicProperty({
        name: 'Codigos de paciente',
        keyInApi: 'lineasSolicitud.codigoPaciente',
        type: PropertyType.String(),
        nullable: true,
        dontReadFromApi: true,
        filters: [PartialSearchFilter]
    })
    private _codigosPaciente;

    @BasicProperty({
        name: 'Métodos de fabricación y materiales',
        type: PropertyType.String(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get metodosFabricacionMateriales(): string {
        return this._lineas.map(l => l.metodoFabricacion ? (l.metodoFabricacion.descripcion + ' (' + l.material?.descripcion + ')') : '').join(', ');
    }

    @BasicProperty({
        name: 'Método de fabricación',
        keyInApi: 'lineasSolicitud.metodoFabricacion',
        type: PropertyType.Uri(MetodoFabricacion),
        nullable: true,
        dontReadFromApi: true,
        filters: [ExactSearchFilter]
    })
    private _metodosFabricacion;


    @BasicProperty({
        name: 'Material',
        keyInApi: 'lineasSolicitud.material',
        type: PropertyType.Uri(Material),
        nullable: true,
        dontReadFromApi: true,
        filters: [ExactSearchFilter]
    })
    private _materiales;

    @BasicProperty({
        name: 'Comentarios revisión',
        type: PropertyType.NestedModel(ComentarioRevision, 'solicitud', NestedBehavior.CreateOnly),
        array: true,
        nullable: true
    })
    private _comentariosRevision;

    @BasicProperty({
        name: 'Imprimir modelo',
        type: PropertyType.Boolean()
    })
    private _imprimirModelo: boolean;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get cliente(): Cliente {
        return this._cliente;
    }

    set cliente(value: Cliente) {
        this._cliente = value;
    }

    get lineas(): LineaSolicitud[] {
        return this._lineas;
    }

    set lineas(value: LineaSolicitud[]) {
        this._lineas = value;
    }

    get estado() {
        return this._estado;
    }

    set estado(value) {
        this._estado = value;
    }

    get codigosPaciente() {
        return this._lineas.map(l => l.codigoPaciente).join(', ');
    }

    set codigosPaciente(value) {
        this._codigosPaciente = value;
    }

    get metodosFabricacion() {
        return this._metodosFabricacion;
    }

    set metodosFabricacion(value) {
        this._metodosFabricacion = value;
    }

    get materiales() {
        return this._materiales;
    }

    set materiales(value) {
        this._materiales = value;
    }

    get pedido(): Pedido {
        return this._pedido;
    }

    set pedido(value: Pedido) {
        this._pedido = value;
    }

    get imagenes(): ArchivoImagenSolicitud[] {
        return this._imagenes;
    }

    set imagenes(value: ArchivoImagenSolicitud[]) {
        this._imagenes = value;
    }

    get stlSolicitud() {
        return this._stlSolicitud;
    }

    set stlSolicitud(value) {
        this._stlSolicitud = value;
    }

    get comentariosRevision() {
        return this._comentariosRevision;
    }

    set comentariosRevision(value) {
        this._comentariosRevision = value;
    }

    get imprimirModelo(): boolean {
        return this._imprimirModelo;
    }

    set imprimirModelo(value: boolean) {
        this._imprimirModelo = value;
    }
}

LineaSolicitud.MAP_SOLICITUD.type = PropertyType.Uri(Solicitud);
// PreAlbaran.MAP_SOLICITUD.type = PropertyType.Uri(Solicitud);
ArchivoImagenSolicitud.SOLICITUD_MAP.type = PropertyType.Uri(Solicitud);
ArchivoSTLSolicitud.SOLICITUD_MAP.type = PropertyType.Uri(Solicitud);
ComentarioRevision.SOLICITUD_MAP.type = PropertyType.Uri(Solicitud);
