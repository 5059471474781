import {Component, OnDestroy, OnInit} from '@angular/core';
import {Widget} from "../widget";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {LineaPedido} from "../../../model/pedidos/linea-pedido.model";
import {FilterAndData, FilterList} from "../../../api/filter-list";
import {ExactSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-piezas-en-transito-widget',
    templateUrl: './piezas-en-transito-widget.component.html',
    styleUrls: ['./piezas-en-transito-widget.component.scss']
})
export class PiezasEnTransitoWidgetComponent implements Widget, OnInit, OnDestroy {
    public static minCols = 1;
    public static minRows = 2;
    public static maxRows = 2;
    public static maxCols = 1;
    public static widgetName = 'Pedidos en tránsito';
    public static widgetKey = 'en-transito';
    numeroPiezas: number = 0;
    private _interval: any;
    private _intervalSeconds = 10;

    constructor(private interfaceProvider: InterfaceProviderService) {
        this.execute();
    }

    ngOnInit(): void {
        this._interval = setInterval(
            () => this.execute(),
            this._intervalSeconds * 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this._interval);
    }

    private execute() {
        this.numeroPiezas = 0;
        const estadoProp = this.interfaceProvider.interfaceForModel(LineaPedido).mappingModelToApi['estado'];
        const filters: FilterList = [new FilterAndData(ExactSearchFilter, [LineaPedido.ESTADOS_LINEA_PEDIDO.EN_TRANSITO], estadoProp)];
        this.interfaceProvider.managerForModel(LineaPedido).loader.findAndFollow(filters).pipe(map(r => {
            return r.member;
        })).subscribe(ps => {
            this.numeroPiezas += ps.length;
        })
    }
}

