import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {ManagerCachingStrategy} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";
import {Uri} from "../../api/uri";
import {TipoLlave} from "./tipo-llave.model";
import {Asiento} from "./asiento.model";
import {Marca} from "./marca.model";
import {Proveedor} from "../personas/proveedor.model";
import {Dimension} from "./dimension.model";
import {Sistema} from "./sistema.model";
import {Cabeza} from "./cabeza.model";
import {Metrica} from "./metrica.model";
import {Angulacion} from "./angulacion.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";

@BasicEntity({
    name: 'Tornillos',
    endpoint: 'tornillos',
    isPaginated: false,
    type: 'Tornillo',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class Tornillo extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Referencia',
        type: PropertyType.String()
    })
    private _referencia: string;

    @BasicProperty({
        name: 'Referencia Fabricante',
        type: PropertyType.String()
    })
    private _referenciaProveedor: string;

    @BasicProperty({
        name: 'Marca',
        type: PropertyType.NestedModel(Marca, null, NestedBehavior.SendIriOnly)
    })
    private _marca;

    @BasicProperty({
        name: 'Sistema',
        type: PropertyType.NestedModel(Sistema, null, NestedBehavior.SendIriOnly)
    })
    private _sistema;

    @BasicProperty({
        name: 'Ajuste Dinámico',
        type: PropertyType.Boolean()
    })
    private _dinamico: boolean;

    @BasicProperty({
        name: "Tipo de Llave",
        type: PropertyType.NestedModel(TipoLlave, null, NestedBehavior.SendIriOnly)
    })
    private _tipoLlave;

    @BasicProperty({
        name: "Dimensión",
        type: PropertyType.NestedModel(Dimension, null, NestedBehavior.SendIriOnly)
    })
    private _plataforma;

    @BasicProperty({
        name: 'Proveedores',
        type: PropertyType.NestedModel(Proveedor, null, NestedBehavior.SendIriOnly),
        array: true
    })
    private _proveedores: Proveedor[];

    @BasicProperty({
        name: 'Asiento',
        type: PropertyType.NestedModel(Asiento, null, NestedBehavior.SendIriOnly)
    })
    private _asiento;

    @BasicProperty({
        name: 'Cabeza',
        type: PropertyType.NestedModel(Cabeza, null, NestedBehavior.SendIriOnly)
    })
    private _cabeza;

    @BasicProperty({
        name: 'Metrica',
        type: PropertyType.NestedModel(Metrica, null, NestedBehavior.SendIriOnly)
    })
    private _metrica;

    @BasicProperty({
        name: 'Angulación',
        type: PropertyType.NestedModel(Angulacion, null, NestedBehavior.SendIriOnly)
    })
    private _angulacion;

    @BasicProperty({
        name: 'Precio Compra Unitario',
        type: PropertyType.Financial(),

    })
    private _precioCompraUnitario;

    @BasicProperty({
        name: "Precio Venta Unitario",
        type: PropertyType.Financial()
    })
    private _precioVentaUnitario;

    @BasicProperty({
        name: 'Stock',
        type: PropertyType.Integer()
    })
    private _stock;

    @BasicProperty({
        name: 'StockMinimo',
        type: PropertyType.Integer()
    })
    private _stockMinimo;

    @BasicProperty({
        name: 'Coord X en Almacen',
        type: PropertyType.String()
    })
    private _almacenX;

    @BasicProperty({
        name: 'Coord Y en Almacen',
        type: PropertyType.String()
    })
    private _almacenY;


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get referencia(): string {
        return this._referencia;
    }

    set referencia(value: string) {
        this._referencia = value;
    }

    get referenciaProveedor(): string {
        return this._referenciaProveedor;
    }

    set referenciaProveedor(value: string) {
        this._referenciaProveedor = value;
    }

    get marca() {
        return this._marca;
    }

    set marca(value) {
        this._marca = value;
    }

    get dinamico(): boolean {
        return this._dinamico;
    }

    set dinamico(value: boolean) {
        this._dinamico = value;
    }

    get tipoLlave() {
        return this._tipoLlave;
    }

    set tipoLlave(value) {
        this._tipoLlave = value;
    }

    get plataforma() {
        return this._plataforma;
    }

    set plataforma(value) {
        this._plataforma = value;
    }

    get proveedores(): Proveedor[] {
        return this._proveedores;
    }

    set proveedores(value: Proveedor[]) {
        this._proveedores = value;
    }

    get asiento() {
        return this._asiento;
    }

    set asiento(value) {
        this._asiento = value;
    }

    get precioCompraUnitario() {
        return this._precioCompraUnitario;
    }

    set precioCompraUnitario(value) {
        this._precioCompraUnitario = value;
    }

    get precioVentaUnitario() {
        return this._precioVentaUnitario;
    }

    set precioVentaUnitario(value) {
        this._precioVentaUnitario = value;
    }

    get stock() {
        return this._stock;
    }

    set stock(value) {
        this._stock = value;
    }

    get stockMinimo() {
        return this._stockMinimo;
    }

    set stockMinimo(value) {
        this._stockMinimo = value;
    }

    get almacenX() {
        return this._almacenX;
    }

    set almacenX(value) {
        this._almacenX = value;
    }

    get almacenY() {
        return this._almacenY;
    }

    set almacenY(value) {
        this._almacenY = value;
    }

    get sistema() {
        return this._sistema;
    }

    set sistema(value) {
        this._sistema = value;
    }

    get cabeza() {
        return this._cabeza;
    }

    set cabeza(value) {
        this._cabeza = value;
    }

    get metrica() {
        return this._metrica;
    }

    set metrica(value) {
        this._metrica = value;
    }

    get angulacion() {
        return this._angulacion;
    }

    set angulacion(value) {
        this._angulacion = value;
    }
}
