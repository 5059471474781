import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy, PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {LineaPedidoOpcion} from "../pedidos/linea-pedido-opcion.model";
import {LineaPedido} from "../pedidos/linea-pedido.model";
import {TarifaOpcion} from "../trabajos/tarifa-opcion.model";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Material} from "../materiales/material.model";
import {Trabajo} from "../trabajos/trabajo.model";
import {SubTrabajo} from "../trabajos/subtrabajo.model";
import {TipoMaterial} from "../materiales/tipo-material.model";
import {Opcion} from "../trabajos/opcion.model";
import {RangeFilters} from "../../basic-entity-back/filters/range-filters";
import {NumericFilter} from "../../basic-entity-back/filters/numeric-filter";

@BasicEntity({
    name: "Descuento genérico",
    endpoint: "descuento_generals",
    isPaginated: false,
    type: "DescuentoGeneral",
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'descripcion'
})
export class DescuentoGeneral extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id: number;

    @BasicProperty({
        name: 'Descripción',
        type: PropertyType.String()
    })
    private _descripcion: string;

    @BasicProperty({
        name: 'Método',
        type: PropertyType.NestedModel(MetodoFabricacion, undefined, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _metodoFabricacion: MetodoFabricacion | null;

    @BasicProperty({
        name: 'Material',
        type: PropertyType.NestedModel(Material, undefined, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _material: Material | null;

    @BasicProperty({
        name: 'Trabajo',
        type: PropertyType.NestedModel(Trabajo, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _trabajo: Trabajo | null;

    @BasicProperty({
        name: 'Subtrabajo',
        type: PropertyType.NestedModel(SubTrabajo, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _subTrabajo: SubTrabajo | null;

    @BasicProperty({
        name: 'Acabado',
        type: PropertyType.NestedModel(TipoMaterial, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _acabado: TipoMaterial | null;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get descripcion(): string {
        return this._descripcion;
    }

    set descripcion(value: string) {
        this._descripcion = value;
    }

    get metodoFabricacion(): MetodoFabricacion | null {
        return this._metodoFabricacion;
    }

    set metodoFabricacion(value: MetodoFabricacion | null) {
        this._metodoFabricacion = value;
    }

    get material(): Material | null {
        return this._material;
    }

    set material(value: Material | null) {
        this._material = value;
    }

    get trabajo(): Trabajo | null {
        return this._trabajo;
    }

    set trabajo(value: Trabajo | null) {
        this._trabajo = value;
    }

    get subTrabajo(): SubTrabajo | null {
        return this._subTrabajo;
    }

    set subTrabajo(value: SubTrabajo | null) {
        this._subTrabajo = value;
    }

    get acabado(): TipoMaterial | null {
        return this._acabado;
    }

    set acabado(value: TipoMaterial | null) {
        this._acabado = value;
    }
}
