import {Resource} from "../../api/resource";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {ManagerCachingStrategy, PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {DireccionCentro} from "./centro-produccion-direccion.model";
import {Empleado} from "../personas/empleado.model";

@BasicEntity({
    name: 'Centros de Producción',
    endpoint: 'centro_produccions',
    isPaginated: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'nombre'
})
export class CentroProduccion extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String()
    })
    private _nombre: string;

    @BasicProperty({
        name: 'Direcciones',
        type: PropertyType.NestedModel(DireccionCentro, null, NestedBehavior.CreateOnly),
        array: true
    })
    private _direcciones;

    @BasicProperty({
        name: 'Empleados',
        type: PropertyType.Uri(Empleado),
        array: true
    })
    private _empleados;

    public static readonly METODOS_MAP: PropertyMap = {
        name: 'Métodos de Fabricación',
        type: PropertyType.FutureUri('metodo-fabricacion.model.ts'),
        array: true
    }
    @BasicProperty(CentroProduccion.METODOS_MAP)
    private _metodos: MetodoFabricacion[];

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get nombre(): string {
        return this._nombre;
    }

    set nombre(value: string) {
        this._nombre = value;
    }

    get direcciones() {
        return this._direcciones;
    }

    set direcciones(value) {
        this._direcciones = value;
    }

    get empleados() {
        return this._empleados;
    }

    set empleados(value) {
        this._empleados = value;
    }

    get metodos(): MetodoFabricacion[] {
        return this._metodos;
    }

    set metodos(value: MetodoFabricacion[]) {
        this._metodos = value;
    }
}

DireccionCentro.CENTRO_MAP.type = PropertyType.Uri(CentroProduccion)
