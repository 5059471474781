import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {ManagerCachingStrategy} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";

@BasicEntity({
    name: 'Color',
    endpoint: 'color_materials',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    modelNameProperty: 'descripcion',
    type: 'ColorMaterial',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class ColorMaterial extends Resource{

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Descripción del color del material',
        type: PropertyType.String()
    })
    private _descripcion;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }
}
