import {Resource} from "../../api/resource";
import {Uri} from "../../api/uri";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Municipio} from "../localizacion/municipio.model";
import {Provincia} from "../localizacion/provincia.model";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Pais} from "../localizacion/pais.model";


@BasicEntity({
    name: "Dirección",
    endpoint: "direccion_centros",
    isPaginated: false,
})
export class DireccionCentro extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Calle',
        type: PropertyType.String(),
        nullable: true
    })
    private _calle?: string;

    @BasicProperty({
        name: 'Código Postal',
        type: PropertyType.String(),
        nullable: true
    })
    private _cp?: string;

    @BasicProperty({
        name: 'Municipio',
        type: PropertyType.Uri(Municipio)
    })
    private _poblacion: Uri;

    @BasicProperty({
        name: 'Provincia',
        type: PropertyType.Uri(Provincia),
        readWrite: ReadWrite.ReadOnly
    })
    private _provincia: Uri;

    @BasicProperty({
        name: 'País',
        type: PropertyType.Uri(Pais)
    })
    private _pais: Uri;

    public static readonly CENTRO_MAP: PropertyMap = {
        name: 'Centro',
        type: PropertyType.FutureUri('centro-produccion.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(DireccionCentro.CENTRO_MAP)
    private _centro: Uri;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get calle(): string {
        return this._calle;
    }

    set calle(value: string) {
        this._calle = value;
    }

    get cp(): string {
        return this._cp;
    }

    set cp(value: string) {
        this._cp = value;
    }

    get poblacion(): Uri {
        return this._poblacion;
    }

    set poblacion(value: Uri) {
        this._poblacion = value;
    }

    get provincia(): Uri {
        return this._provincia;
    }

    set provincia(value: Uri) {
        this._provincia = value;
    }

    get pais(): Uri {
        return this._pais;
    }

    set pais(value: Uri) {
        this._pais = value;
    }

    get centro(): Uri {
        return this._centro;
    }

    set centro(value: Uri) {
        this._centro = value;
    }
}
