<mat-progress-bar mode="indeterminate" color="primary" *ngIf="cargando"></mat-progress-bar>
<div class="titulo">Últimas facturas disponibles</div>
<div class="lineas">
    <div class="linea" *ngFor="let factura of facturas">
        <div>{{ factura.fecha | date: 'dd/MM/y' }}</div>
        <div>{{ factura.numeroFactura }}</div>
        <div>{{ factura.importeTotal }}€</div>
        <div (click)="descargar(factura)">
            <mat-icon>download</mat-icon>
        </div>
    </div>
</div>
