import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Http, ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {SessionService} from "../session/session.service";

@Injectable({providedIn: 'root'})

export class HashService {

    constructor(private http: HttpClient, private loginService: SessionService) {
    }

    decodeHash(url: string): Observable<any> {
        const params = new HttpHeaders().append('Authorization', 'Bearer ' + this.loginService.token.token);
        return this.http.get(environment.apiUrl + '/' + url, {responseType: 'text', headers: params});
    }
}
