import {Component, OnDestroy, OnInit} from '@angular/core';
import {Widget} from "../widget";
import * as moment from "moment";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {Incidencia} from "../../../model/incidencias/incidencia.model";

@Component({
    selector: 'app-incidencias-widget',
    templateUrl: './incidencias-widget.component.html',
    styleUrls: ['./incidencias-widget.component.scss']
})
export class IncidenciasWidgetComponent implements Widget, OnInit, OnDestroy {
    public static minCols = 1;
    public static minRows = 2;
    public static maxRows = 2;
    public static maxCols = 1;
    public static widgetName = 'Incidencias pendientes';
    public static widgetKey = 'incidencias-pendientes';
    numeroIncidencias: number = 0;
    private _interval: any;
    private _intervalSeconds = 10;

    constructor(private interfaceProvider: InterfaceProviderService) {
        this.execute();
    }

    ngOnInit(): void {
        this._interval = setInterval(
            () => this.execute(),
            this._intervalSeconds * 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this._interval);
    }

    private execute() {
        this.numeroIncidencias = 0;
        this.interfaceProvider.managerForModel(Incidencia).all.subscribe(incidencias => {
            if (incidencias.length > 0) {
                incidencias.forEach(incidencia => {
                    if (incidencia.estado === Incidencia.ESTADOS_INCIDENCIA.PENDIENTE || incidencia.estado === Incidencia.ESTADOS_INCIDENCIA.EN_CURSO) {
                        this.numeroIncidencias++;
                    }
                })
            }
        })
    }
}


