import {Component, OnInit, Type} from '@angular/core';
import {SessionService} from "../../../../session/session.service";
import {Cliente} from "../../../../model/personas/cliente.model";
import {InterfaceProviderService} from "../../../../basic-entity-back/services/interface-provider.service";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {PersonaContacto} from "../../../../model/personas/contacto.model";
import {DireccionPersona} from "../../../../model/personas/direccion-persona.model";
import {Usuario} from "../../../../model/personas/usuario.model";
import {TelefonoPersona} from "../../../../model/personas/telefono-persona.model";
import {Pais} from "../../../../model/localizacion/pais.model";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";
import {Resource} from "../../../../api/resource";
import {clone} from "three/examples/jsm/utils/SkeletonUtils";
import {ErrorDisplayService} from "../../../../basic-entity-front/services/error-display.service";
import {isObject} from "rxjs/internal-compatibility";
import {Provincia} from "../../../../model/localizacion/provincia.model";
import {FilterAndData} from "../../../../api/filter-list";
import {ExactSearchFilter} from "../../../../basic-entity-back/filters/search-filter";
import {error} from "protractor";

@Component({
    selector: 'app-cliente-edition',
    templateUrl: './cliente-edition.component.html',
    styleUrls: ['./cliente-edition.component.scss']
})
export class ClienteEditionComponent implements OnInit {

    public me: Cliente;
    public usuario: Usuario;
    public tempContact: PersonaContacto;
    public tempAddress: DireccionPersona;
    public tempPhone: TelefonoPersona;
    public formGroup: UntypedFormGroup;
    public tempResource: Resource;
    public tempContactForm: UntypedFormGroup;
    public tempAddressForm: UntypedFormGroup;
    public tempPhoneForm: UntypedFormGroup;
    public tempContactId: number = -1;
    public tempAddressId: number = -1;
    public tempPhoneId: number = -1;
    public addContact: boolean = false;
    public addAddress: boolean = false;
    public addPhone: boolean = false;
    public dataChanged: boolean = false;
    public paises: Pais[] = [];
    public provincias: Provincia[] = [];
    public filteredPaises: Observable<Pais[]>;
    public filteredProvincias: Observable<Provincia[]>;
    public filtroPais = [];
    public mostrarDireccionFacturacion = true;
    public mostrarDireccionEnvio = true;
    public dirEnvio = DireccionPersona.TIPOS_DIRECCION.ENVIO;
    public dirFact = DireccionPersona.TIPOS_DIRECCION.FACTURACION;
    protected readonly DireccionPersona = DireccionPersona;

    constructor(private _session: SessionService, private _intf: InterfaceProviderService, private fb: UntypedFormBuilder,
                private errorService: ErrorDisplayService) {
    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({});
        this.tempContactForm = new UntypedFormGroup({
            id: new UntypedFormControl(0),
            nombre: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
            apellido1: new UntypedFormControl(''),
            apellido2: new UntypedFormControl(''),
            cargo: new UntypedFormControl(''),
            email: new UntypedFormControl('')
        });
        this.tempAddressForm = new UntypedFormGroup({
            id: new UntypedFormControl(0),
            calle: new UntypedFormControl(''),
            tipoDireccion: new UntypedFormControl("envio"),
            poblacion: new UntypedFormControl(''),
            demarcacion: new UntypedFormControl(null),
            cp: new UntypedFormControl(''),
            pais: new UntypedFormControl(null),
            mismaDireccion: new UntypedFormControl(false)
        }, {validators: [this.validarDireccionFn]});
        this.tempPhoneForm = new UntypedFormGroup({
            id: new UntypedFormControl(0),
            codigoArea: new UntypedFormControl('+34', [Validators.maxLength(3)]),
            telefono: new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
            extension: new UntypedFormControl(''),
            departamento: new UntypedFormControl('')
        });

        this.filteredPaises = this.tempAddressForm.get('pais').valueChanges.pipe(startWith(''), map(value => {
            if (value) {
                const pais = this.tempAddressForm.get('pais').value;
                const provincia = this.tempAddressForm.get('demarcacion').value;
                if (pais instanceof Pais && provincia instanceof Provincia && pais.iri.toString() !== provincia.pais.toString()) {
                    this.tempAddressForm.get('demarcacion').reset();
                }

                return this.paises.filter(pais => {
                    return pais.nombreDefault.toLowerCase().includes(value.nombreDefault?.toLowerCase() ?? value.toLowerCase())
                });
            }
        }));

        this.filteredProvincias = this.tempAddressForm.get('demarcacion').valueChanges.pipe(startWith(''), map(value => {
            if (value) {
                const pais = this.tempAddressForm.get('pais').value;
                const provincia = this.tempAddressForm.get('demarcacion').value;
                if (pais instanceof Pais && provincia instanceof Provincia && pais.iri.toString() !== provincia.pais.toString()) {
                    this.tempAddressForm.get('pais').reset();
                }

                return this.provincias.filter(provincia => {
                    return provincia.nombre.toLowerCase().includes(value.nombre?.toLowerCase() ?? value.toLowerCase())
                });
            }
        }));

        this.filtroProvincias();
        this.filtroPaises([]);

        this._session.profileAsObservable.subscribe(result => {
            if (result) {
                this.me = result as Cliente;
                this.usuario = this.me.usuario;
                const props = Object.keys(this._intf.interfaceForModel(Cliente).mappingModelToApi);
                props.forEach(entry => {
                    this.formGroup.addControl(entry, this.fb.control(this.me[entry] ?? ''))
                });
                const initialValue = this.formGroup.value;
                this.formGroup.valueChanges.subscribe(valor => this.dataChanged = Object.keys(initialValue).some(k => {
                    return this.formGroup.value[k] !== initialValue[k]
                }));

                this.mostrarDireccionFacturacion = this.me.direcciones.filter(d => d.tipoDireccion === this.dirFact).length === 0;
                this.mostrarDireccionEnvio = this.me.direcciones.filter(d => d.tipoDireccion === this.dirEnvio).length === 0;
            }
        })
    }

    filtroProvincias() {
        this._intf.managerForModel(Provincia).loader.findAndFollow().pipe(map(page => page.member)).subscribe(provincias => {
            this.provincias.push(...provincias);
        });
    }

    filtroPaises(filtro) {
        this._intf.managerForModel(Pais).loader.findAndFollow(filtro).pipe(map(page => page.member)).subscribe(paises => {
            this.paises.push(...paises);
        });
    }

    cambioPersona(event) {
        this.me.empresa = this.formGroup.get('empresa').value;
        if (event.value === false) {
            ["nombre", "apellido1", "apellido2", "cifNif", "email"].forEach(p => this.formGroup.get(p).enable());
            ["denominacionSocial"].forEach(p => {
                // this.formGroup.get(p).setValue(null);
                this.formGroup.get(p).disable();
            });
        } else {
            ["nombre", "apellido1", "apellido2"].forEach(p => {
                // this.formGroup.get(p).setValue(null);
                this.formGroup.get(p).disable();
            });
            ["denominacionSocial", "cifNif", "email"].forEach(p => this.formGroup.get(p).enable())
        }
    }

    guardarDatos() {
        const descriptors = this._intf.interfaceForModel(Cliente).serialiser.getModelDescriptors();
        for (const [k, v] of Object.entries(descriptors)) {
            if (v['set']) {
                this.me[k] = this.formGroup.get(k).value
            }
        }
        this._intf.managerForModel(Cliente).update(this.me).subscribe(result => console.log(result));
        this.dataChanged = false;
        this.errorService.displayRaw("Datos actualizados correctamente", 1500, ['mat-snackbar-ok']);
    }


    validarDireccionFn(control: AbstractControl): ValidationErrors | null {
        if (control) {
            const controles = (control as UntypedFormGroup).controls;
            for (let [index, con] of Object.entries(controles)) {
                (con as UntypedFormControl).validator = (contr: AbstractControl): ValidationErrors | null => {
                    if (index !== 'id' && index !== 'mismaDireccion' && (con.value === "" || con.value === null || (index === 'pais' && !(con.value instanceof Pais)) || (index === 'demarcacion' && !(con.value instanceof Provincia)))) {
                        return {invalid: true}
                    }
                };
            }
        }
        return null;
    }

    guardarContacto() {
        this.addContact = false;
        if (this.tempContactId === -1) {
            const newContact = this._intf.interfaceForModel(PersonaContacto).serialiser.getEmptyModel();
            newContact.cargo = this.tempContactForm.get('cargo').value;
            newContact.email = this.tempContactForm.get('email').value;
            newContact.nombre = this.tempContactForm.get('nombre').value;
            newContact.apellido1 = this.tempContactForm.get('apellido1').value;
            newContact.apellido2 = this.tempContactForm.get('apellido2').value;
            this.me.contactos.push(newContact);
        } else {
            this.tempContact.cargo = this.tempContactForm.get('cargo').value;
            this.tempContact.email = this.tempContactForm.get('email').value;
            this.tempContact.nombre = this.tempContactForm.get('nombre').value;
            this.tempContact.apellido1 = this.tempContactForm.get('apellido1').value;
            this.tempContact.apellido2 = this.tempContactForm.get('apellido2').value;
            this.me.contactos.splice(this.tempContactId, 1, this.tempContact);
            this.tempContactId = -1;
        }
        this.actualizar(this.tempContact);
        this.tempContactForm.reset();
    }

    editarContacto(contacto: PersonaContacto, index: number) {
        console.log(contacto)
        this.tempContactId = index;
        this.tempContact = contacto;
        this.addContact = true;
        this.tempContactForm.get('id').setValue(contacto.id)
        this.tempContactForm.get('cargo').setValue(contacto.cargo)
        this.tempContactForm.get('email').setValue(contacto.email);
        this.tempContactForm.get('nombre').setValue(contacto.nombre);
        this.tempContactForm.get('apellido1').setValue(contacto.apellido1);
        this.tempContactForm.get('apellido2').setValue(contacto.apellido2);
    }

    borrarContacto(contacto: PersonaContacto, index: number) {
        this.me.contactos.splice(index, 1)
        this.actualizar(null)
    }

    guardarDireccion() {
        this.addAddress = false;
        if (this.tempAddressId === -1) {
            const newAddress = this._intf.interfaceForModel(DireccionPersona).serialiser.getEmptyModel();
            newAddress.calle = this.tempAddressForm.get('calle').value;
            newAddress.tipoDireccion = this.tempAddressForm.get('tipoDireccion').value;
            newAddress.cp = this.tempAddressForm.get('cp').value;
            newAddress.poblacion = this.tempAddressForm.get('poblacion').value;
            newAddress.demarcacion = this.tempAddressForm.get('demarcacion').value;
            newAddress.pais = this.tempAddressForm.get('pais').value;

            if (this.tempAddressForm.get('mismaDireccion').value && this.me.direcciones.length === 0) {
                const dir = this._intf.interfaceForModel(DireccionPersona).serialiser.clone(newAddress);
                dir.tipoDireccion = dir.tipoDireccion === this.dirEnvio ? this.dirFact : this.dirEnvio;
                this.me.direcciones.push(dir)
            }

            this.me.direcciones.push(newAddress);

        } else {
            this.tempAddress.calle = this.tempAddressForm.get('calle').value;
            this.tempAddress.tipoDireccion = this.tempAddressForm.get('tipoDireccion').value;
            this.tempAddress.cp = this.tempAddressForm.get('cp').value;
            this.tempAddress.poblacion = this.tempAddressForm.get('poblacion').value;
            this.tempAddress.demarcacion = this.tempAddressForm.get('demarcacion').value;
            this.tempAddress.pais = this.tempAddressForm.get('pais').value;
            this.me.direcciones.splice(this.tempAddressId, 1, this.tempAddress);
            this.tempAddressId = -1;
        }
        this.actualizar(this.tempAddress)
        this.tempAddressForm.reset();
    }

    editarDireccion(direccion: DireccionPersona, index: number) {
        this.tempAddressId = index;
        this.tempAddress = direccion;
        this.addAddress = true;
        this.tempAddressForm.get('id').setValue(direccion.id);
        this.tempAddressForm.get('calle').setValue(direccion.calle);
        this.tempAddressForm.get('tipoDireccion').setValue(direccion.tipoDireccion);
        this.tempAddressForm.get('cp').setValue(direccion.cp);
        this.tempAddressForm.get('poblacion').setValue(direccion.poblacion);
        this.tempAddressForm.get('demarcacion').setValue(direccion.demarcacion);
        this.tempAddressForm.get('pais').setValue(direccion.pais);
    }

    borrarDireccion(direccion: DireccionPersona, index: number) {
        this.me.direcciones.splice(index, 1);
        this.actualizar(null);
    }

    guardarTelefono() {
        this.addPhone = false;
        if (this.tempPhoneId === -1) {
            const newPhone = new TelefonoPersona();
            newPhone.codigoArea = this.tempPhoneForm.get('codigoArea').value;
            newPhone.telefono = this.tempPhoneForm.get('telefono').value;
            newPhone.extension = this.tempPhoneForm.get('extension').value;
            newPhone.departamento = this.tempPhoneForm.get('departamento').value
            this.me.telefonos.push(newPhone);
        } else {
            this.tempPhone.idTelefono = this.tempPhoneForm.get('id').value;
            this.tempPhone.codigoArea = this.tempPhoneForm.get('codigoArea').value;
            this.tempPhone.telefono = this.tempPhoneForm.get('telefono').value;
            this.tempPhone.extension = this.tempPhoneForm.get('extension').value;
            this.tempPhone.departamento = this.tempPhoneForm.get('departamento').value;
            this.me.telefonos.splice(this.tempPhoneId, 1, this.tempPhone);
            this.tempContactId = -1;
        }
        this.actualizar(this.tempPhone);
        this.tempPhoneForm.reset();
    }

    editarTelefono(telefono: TelefonoPersona, index: number) {
        this.tempPhoneId = index;
        this.tempPhone = telefono;
        this.addPhone = true;
        this.tempPhoneForm.get('id').setValue(telefono.idTelefono);
        this.tempPhoneForm.get('codigoArea').setValue(telefono.codigoArea);
        this.tempPhoneForm.get('telefono').setValue(telefono.telefono);
        this.tempPhoneForm.get('extension').setValue(telefono.extension);
        this.tempPhoneForm.get('departamento').setValue(telefono.departamento);
    }


    borrarTelefono(telefono: TelefonoPersona, index: number) {
        this.me.telefonos.splice(index, 1);
        this.actualizar(null);
    }

    displayProperty(value) {
        if (value && value.nombreDefault) {
            return value.nombreDefault;
        } else if (value && value.nombre) {
            return value.nombre;
        }
    }

    nombrePais(iri: string) {
        return this.paises.find(pais => pais.iri.toString() === iri)?.nombreDefault;
    }

    nombreProvincia(iri: string) {
        return this.provincias.find(provincia => provincia.iri.toString() === iri)?.nombre;
    }

    private actualizar(toReset: any) {
        console.log(this.me)

        this._intf.managerForModel(Cliente).update(this.me).subscribe(persona => {
            (this.me = persona) && (toReset = null);
            this.errorService.displayRaw("Datos actualizados correctamente", 1500, ['mat-snackbar-ok']);


            this.mostrarDireccionFacturacion = this.me.direcciones.filter(d => d.tipoDireccion === this.dirFact).length === 0;
            this.mostrarDireccionEnvio = this.me.direcciones.filter(d => d.tipoDireccion === this.dirEnvio).length === 0;
        }, error => {
            if (error.error['hydra:description'].indexOf('contactos') > -1) {
                this.me.contactos.pop();
            }
        });

    }

    control(val: string, form?: UntypedFormGroup) {
        if (this.me) {
            if (!form) {
                return this.formGroup.get(val) as UntypedFormControl;
            } else {
                return form.get(val) as UntypedFormControl;
            }
        }
    }

    property(value: string) {
        return this._intf.interfaceForModel(DireccionPersona).mappingModelToApi[value];
    }

}
