import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Cliente} from "../personas/cliente.model";
import {LineaPedido} from "./linea-pedido.model";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {ManagerCachingStrategy, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {BaseEntity} from "../base-entity.model";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {Material} from "../materiales/material.model";
import {Albaran} from "../documentacion/albaran.model";

@BasicEntity({
    name: 'Pedidos',
    endpoint: 'pedidos',
    modelNameProperty: 'resumen',
    isPaginated: true,
    managerCaching: ManagerCachingStrategy.Lazy
})
export class Pedido extends BaseEntity {
    static readonly ESTADOS_PEDIDO = {
        // PENDIENTE: 'Pendiente',
        // CONFIRMADO: "Confirmado",
        // VALIDADO: 'Validado',
        // EN_PRODUCCION: 'En producción',
        // FINALIZADO: 'Finalizado',
        // ENVIADO: 'En tránsito',
        // EN_REPARTO: 'En reparto',
        // CANCELADO: 'Cancelado'
        EN_CURSO: "En curso",
        REALIZADO: "Realizado",
        RECHAZADO: "Rechazado",
        PENDIENTE_VALIDAR: "Pendiente de validación",
        VALIDADO: "Validado",
        EN_PRODUCCION: "En producción",
        LISTO_PARA_ENVIO: "Listo para envío",
        EN_TRANSITO: "En tránsito",
        ENTREGADO: "Entregado",
        CANCELADO: "Cancelado"
    };

    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Cliente',
        type: PropertyType.NestedModel(Cliente, null, NestedBehavior.SendIriOnly),
        filters: [ExactSearchFilter]
    })
    private _cliente: Cliente;

    @BasicProperty({
        name: 'Líneas',
        keyInApi: 'lineasPedido',
        type: PropertyType.NestedModel(LineaPedido, 'pedido', NestedBehavior.CreateOnly),
        array: true
    })
    private _lineas: LineaPedido[];

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(Pedido.ESTADOS_PEDIDO)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _estado;

    @BasicProperty({
        name: 'Nº de archivos',
        type: PropertyType.Integer(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get numeroArchivos(): number {
        return this.lineas.length;
    }

    @BasicProperty({
        name: 'Codigos de paciente',
        keyInApi: 'lineasPedido.codigoPaciente',
        type: PropertyType.String(),
        nullable: true,
        dontReadFromApi: true,
        filters: [PartialSearchFilter]
    })
    private _codigosPaciente;

    @BasicProperty({
        name: 'Métodos de fabricación y materiales',
        type: PropertyType.String(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get metodosFabricacionMateriales(): string {
        return this._lineas.map(l => l.metodoFabricacion ? (l.metodoFabricacion.descripcion + ' (' + l.material?.descripcion + ')') : '').join(', ');
    }

    @BasicProperty({
        name: 'Método de fabricación',
        keyInApi: 'lineasPedido.metodoFabricacion',
        type: PropertyType.Uri(MetodoFabricacion),
        nullable: true,
        dontReadFromApi: true,
        filters: [ExactSearchFilter]
    })
    private _metodosFabricacion;


    @BasicProperty({
        name: 'Material',
        keyInApi: 'lineasPedido.material',
        type: PropertyType.Uri(Material),
        nullable: true,
        dontReadFromApi: true,
        filters: [ExactSearchFilter]
    })
    private _materiales;

    @BasicProperty({
        name: 'Escaneo asociado',
        type: PropertyType.Object(),
        readWrite: ReadWrite.ReadOnly
    })
    private _solicitud;

    @BasicProperty({
        name: 'Es repetición',
        type: PropertyType.Uri(Pedido),
        readWrite: ReadWrite.ReadOnly
    })
    private _pedidoRepetido;

    @BasicProperty({
        name: 'Motivo de rechazo',
        type: PropertyType.String(),
        nullable: true,
        readWrite: ReadWrite.ReadOnly
    })
    private _motivoRechazo = null;

    get resumen() {
        return 'Pedido ' + this.id
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get cliente(): Cliente {
        return this._cliente;
    }

    set cliente(value: Cliente) {
        this._cliente = value;
    }

    get lineas(): LineaPedido[] {
        return this._lineas;
    }

    set lineas(value: LineaPedido[]) {
        this._lineas = value;
    }

    get estado() {
        return this._estado;
    }

    set estado(value) {
        this._estado = value;
    }

    get codigosPaciente() {
        return this._lineas ? this.lineas.map(l => l.codigoPaciente).join(', ') : '';
    }

    set codigosPaciente(value) {
        this._codigosPaciente = value;
    }

    get metodosFabricacion() {
        return this._metodosFabricacion;
    }

    set metodosFabricacion(value) {
        this._metodosFabricacion = value;
    }

    get materiales() {
        return this._materiales;
    }

    set materiales(value) {
        this._materiales = value;
    }

    get solicitud() {
        return this._solicitud;
    }

    set solicitud(value) {
        this._solicitud = value;
    }

    get pedidoRepetido() {
        return this._pedidoRepetido ? this._pedidoRepetido : '';
    }

    set pedidoRepetido(value) {
        this._pedidoRepetido = value;
    }

    get motivoRechazo(): any {
        return this._motivoRechazo;
    }

    set motivoRechazo(value: any) {
        this._motivoRechazo = value;
    }
}

LineaPedido.MAP_PEDIDO.type = PropertyType.Uri(Pedido);
Albaran.MAP_PEDIDO.type = PropertyType.Uri(Pedido);
