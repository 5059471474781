import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpResponse} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {finalize, tap} from 'rxjs/operators';
import {HttpRequestService} from "./http-request.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(private requestService: HttpRequestService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Aumenta el contador cuando se inicia una solicitud
        this.requestService.incrementRequests();

        return next.handle(req).pipe(
            finalize(() => {
                // Disminuye el contador cuando la solicitud se ha completado
                this.requestService.decrementRequests();
            })
        );
    }
}
