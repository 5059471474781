<div class="basic-entity-table-top">
    <be-filtering2 [properties]="propertiesFromColumns" [dataSource]="dataSource"></be-filtering2>
    <button mat-raised-button (click)="exporter.exportTable('xlsx')">
        Exportar a Excel
    </button>
    <button class="table-menu-btn" mat-icon-button [matMenuTriggerFor]="tableConfigMenu" color="accent">
        <mat-icon>menu</mat-icon>
    </button>
</div>

<mat-menu #tableConfigMenu="matMenu">
    <div cdkDropList (cdkDropListDropped)="tableConfigDrop($event)">
        <button mat-menu-item *ngFor="let col of displayableColumns" (click)="toggleColumn(col.modelKey, $event)"
                cdkDrag>
            <ng-container *ngIf="displayedColumns.indexOf(col.modelKey) >= 0; else hidden">
                <mat-icon color="primary">visibility</mat-icon>
                <span>{{ col.title }}</span>
            </ng-container>
            <ng-template #hidden>
                <mat-icon color="accent">visibility_off</mat-icon>
                <span>{{ col.title }}</span>
            </ng-template>
        </button>
    </div>
    <button mat-menu-item *ngIf="actions.length > 0" (click)="toggleColumn(ACTIONS_COLUMN, $event)">
        <ng-container *ngIf="displayedColumns.indexOf(ACTIONS_COLUMN) >= 0; else hiddenActions">
            <mat-icon color="primary">visibility</mat-icon>
            <span>{{ actionsColumnStyle.colName || '...' }}</span>
        </ng-container>
        <ng-template #hiddenActions>
            <mat-icon color="accent">visibility_off</mat-icon>
            <span>{{ actionsColumnStyle.colName || '...' }}</span>
        </ng-template>
    </button>
</mat-menu>

<mat-progress-bar class="d-block" mode="indeterminate"
                  [style.opacity]="(dataSource.loading$ | async) ? 1 : 0"></mat-progress-bar>

<div class="basic-entity-table">
    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort
               (matSortChange)="dataSource.sortData($event)" [trackBy]="trackByIri"
               [matSortActive]="initialSorting | nullableProp: 'active'"
               [matSortDirection]="initialSorting | nullableProp: 'direction'">
        <ng-container *ngFor="let column of columns; trackBy: trackByModelKey" [matColumnDef]="column.modelKey">
            <mat-header-cell [style.flexGrow]="column.weight" [style.justifyContent]="column.justification"
                             [ngClass]="column.ngClass" *matHeaderCellDef mat-sort-header [disabled]="!column.sortable">
                <mat-icon [matMenuTriggerFor]="filtersMenu" *ngIf="column.original.filters.length > 0"
                          (click)="$event.stopPropagation()" class="filter-icon"
                          [matMenuTriggerData]="{property: column.original}">
                    filter_alt
                </mat-icon>
                <span class="column-title">{{ column.title }}</span>
            </mat-header-cell>
            <mat-cell [style.flexGrow]="column.weight"
                      [ngClass]="column.ngClass + ' ' + (_bloqueador.bloqueado(row) ? 'bloqueado' : '')"
                      *matCellDef="let row"
                      (click)="onElementClick(row, column)">
                <be-cell [style.justifyContent]="column.justification" class="app-basic-entity-cell" [column]="column"
                         [value]="modelValue(row, column)"></be-cell>
            </mat-cell>
        </ng-container>

        <ng-container *ngIf="actions.length > 0" [matColumnDef]="ACTIONS_COLUMN">
            <mat-header-cell *matHeaderCellDef [style.flexGrow]="actionsColumnStyle.weight"
                             [style.justifyContent]="actionsColumnStyle.justification"
                             [ngClass]="actionsColumnStyle.extraClass">
                {{ actionsColumnStyle.colName || 'Acciones' }}
            </mat-header-cell>
            <mat-cell class="colocaBotonesAccion" *matCellDef="let row" [style.flexGrow]="actionsColumnStyle.weight"
                      [style.justifyContent]="actionsColumnStyle.justification"
                      [ngClass]="actionsColumnStyle.extraClass">
                <ng-container *ngIf="actions.length <= 1 && conIconos(); else actionMenuButton">
                    <button *ngIf="!conIconos()" mat-raised-button [matTooltip]="actions[0].tooltip || actions[0].text"
                            [disabled]="actions[0].disabled ? actions[0].disabled(row) : false"
                            (click)="actionClick(actions[0], row)">{{ actions[0].text }}
                    </button>
                    <div *ngIf="conIconos()" class="colocaBotonesAccion">
                        <div *ngFor="let action of actions; let i = index">
                            <mat-icon *ngIf="!hide(i, row)" [class]="action.class"
                                      [matTooltip]="action.tooltip || action.text"
                                      (click)="actionClick(action, row)"> {{ iconoOTexto(action) }}
                            </mat-icon>
                        </div>

                    </div>
                </ng-container>
                <ng-template #actionMenuButton>
                    <!--                    <button mat-raised-button [matMenuTriggerFor]="actionsMenu" (click)="assignRowToContextMenu(row)">-->
                    <!--                        ...-->
                    <!--                    </button>-->
                    <mat-icon [matMenuTriggerFor]="actionsMenu" (click)="assignRowToContextMenu(row)">more_vert
                    </mat-icon>
                </ng-template>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row @fadeInFadeOut *matRowDef="let row; columns: displayedColumns"
                 (contextmenu)="openContextMenu($event, row)" [ngClass]="clase(row)"></mat-row>
    </mat-table>
</div>

<mat-card class="basic-entity-table-empty" *ngIf="! (dataSource.loading$ | async)"
          [style.display]="dataSource.totalItems > 0 ? 'none' : 'block'">
    <mat-card-header>
        <div mat-card-avatar>
            <mat-icon>not_interested</mat-icon>
        </div>
        <mat-card-title>Nada que mostrar</mat-card-title>
        <mat-card-subtitle>La tabla no contiene elementos</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [attr.src]="imageOnEmpty.url" alt="imageOnEmpty.alt" *ngIf="imageOnEmpty">
    <mat-card-content>
        {{ textOnEmpty }}
    </mat-card-content>
    <mat-card-actions *ngIf="allowCreation">
        <button mat-flat-button color="primary" (click)="addNewRow()">Añadir un elemento</button>
    </mat-card-actions>
</mat-card>

<mat-card class="basic-entity-table-loading" *ngIf="dataSource.loading$ | async"
          [style.display]="dataSource.totalItems > 0 ? 'none' : 'block'">
    <mat-card-header>
        <div mat-card-avatar>
            <mat-spinner color="primary" diameter="40"></mat-spinner>
        </div>
        <mat-card-title>Cargando...</mat-card-title>
    </mat-card-header>
    <mat-card-content>Cargando a través de la red...</mat-card-content>
</mat-card>

<div class="{{showPaginator && dataSource.totalItems > 0 ? 'visible':'invisible'}}">
    <mat-icon class="saving-columns-icon" *ngIf="id"
              matTooltip="Las columnas mostradas y su orden se guardan en el navegador">
        save
    </mat-icon>
    <div>
        <mat-paginator [length]="dataSource.totalItems" (page)="pageChange($event)"
                       [showFirstLastButtons]="showFirstLastButtons"></mat-paginator>
    </div>

    <mat-progress-bar class="d-block" mode="indeterminate"
                      [style.opacity]="(dataSource.loading$ | async) ? 1 : 0"></mat-progress-bar>

    <button mat-fab class="mat-fab" color="primary" *ngIf="allowCreation" (click)="addNewRow()">
        <mat-icon>add</mat-icon>
    </button>

    <span type="hidden" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" style="position: fixed;"
          [style.left.px]="menuLeft" [style.top.px]="menuTop"></span>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="contextEdit()" *ngIf="allowEdition">Editar</button>
        <button mat-menu-item *ngIf="allowRemoval" (click)="contextDelete()">Eliminar</button>
        <ng-container *ngIf="actions.length > 0">
            <button mat-menu-item [matMenuTriggerFor]="actionsMenu">Acciones</button>
        </ng-container>
    </mat-menu>
    <mat-menu #actionsMenu="matMenu">
        <label class="titulo-acciones">ACCIONES DISPONIBLES</label>
        <ng-container *ngFor="let action of actions">
            <button mat-menu-item *ngIf="!contextActionDisabled(action)" (click)="contextActionClick(action)">
                <mat-icon>{{ action.icono }}</mat-icon>
                {{ action.text }}
            </button>
        </ng-container>
    </mat-menu>
    <mat-menu #filtersMenu="matMenu">
        <ng-template matMenuContent let-prop="property">
            <button mat-menu-item *ngFor="let filter of prop.filtersUnflatted"
                    (click)="entityFiltering.addFilter(prop, filter)" [matTooltip]="filter.description">
                <i *ngIf="filterOrGroupRepr(filter).icon"
                   [attr.class]="'fas '+filterOrGroupRepr(filter).icon">
                </i>&nbsp;
                <span>{{ filterOrGroupRepr(filter).name }}</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
