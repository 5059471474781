<div class="top">
    <mat-form-field class="add-widget" *ngIf="editionMode">
        <mat-label>Añadir widget...</mat-label>
        <mat-select (valueChange)="addWidget($event)" #addWidgetSelect>
            <mat-option *ngFor="let widget of availableWidgets" [value]="widget">
                {{ widget.widgetName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <span class="separator"></span>
    <mat-slide-toggle color="primary" [(ngModel)]="editionMode">AÑADIR / ELIMINAR WIDGETS</mat-slide-toggle>
</div>
<div class="widgets-container">
    <div *ngFor="let widget of grid.widgets; let idx = index"
         [style.grid-column-start]="widget.column"
         [style.grid-column-end]="widget.column + widget.cols"
         [style.grid-row-start]="widget.row"
         [style.grid-row-end]="widget.row + widget.rows"
         [class.moving]="movingIndex === idx"
         class="widget-wrapper">
        <div class="edit-widget" *ngIf="editionMode">
            <mat-icon draggable="true" class="move-icon"
                      (dragstart)="dragStart($event, idx)"
                      (dragend)="dragEnd($event)">
                apps
            </mat-icon>
            <mat-icon class="delete" color="accent" (click)="deleteWidget(idx)">
                delete
            </mat-icon>
        </div>
        <ng-template [cdkPortalOutlet]="widget.portal"></ng-template>
    </div>
    <div *ngFor="let placeholder of placeholders; let idx = index"
         (dragover)="dragOver($event)"
         (dragenter)="dragEnter($event, idx)"
         (dragexit)="dragExit($event, idx)"
         (drop)="drop($event)"
         [style.grid-column-start]="placeholder.col"
         [style.grid-column-end]="placeholder.col + 1"
         [style.grid-row-start]="placeholder.row"
         [style.grid-row-end]="placeholder.row + 1"
         class="widget-placeholder">
    </div>
</div>
