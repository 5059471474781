import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy, PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {LineaPedidoOpcion} from "../pedidos/linea-pedido-opcion.model";
import {LineaPedido} from "../pedidos/linea-pedido.model";
import {TarifaOpcion} from "./tarifa-opcion.model";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Material} from "../materiales/material.model";
import {Trabajo} from "./trabajo.model";
import {SubTrabajo} from "./subtrabajo.model";
import {TipoMaterial} from "../materiales/tipo-material.model";
import {Opcion} from "./opcion.model";
import {RangeFilters} from "../../basic-entity-back/filters/range-filters";
import {NumericFilter} from "../../basic-entity-back/filters/numeric-filter";

@BasicEntity({
    name: "Descuento del cliente",
    endpoint: "descuento_clientes",
    isPaginated: false,
    type: "DescuentoCliente",
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'descripcion'
})
export class DescuentoCliente extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id: number;

    public static readonly MAP_CLIENTE: PropertyMap = {
        name: 'Cliente',
        type: PropertyType.FutureUri('cliente.model.ts')
    }
    @BasicProperty(DescuentoCliente.MAP_CLIENTE)
    private _cliente;

    @BasicProperty({
        name: 'Método',
        type: PropertyType.NestedModel(MetodoFabricacion, undefined, NestedBehavior.SendIriOnly)
    })
    private _metodoFabricacion: MetodoFabricacion;

    @BasicProperty({
        name: 'Material',
        type: PropertyType.NestedModel(Material, undefined, NestedBehavior.SendIriOnly)
    })
    private _material: Material;

    @BasicProperty({
        name: 'Trabajo',
        type: PropertyType.NestedModel(Trabajo, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _trabajo;

    @BasicProperty({
        name: 'Subtrabajo',
        type: PropertyType.NestedModel(SubTrabajo, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _subTrabajo;

    @BasicProperty({
        name: 'Acabado',
        type: PropertyType.NestedModel(TipoMaterial, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _acabado;

    @BasicProperty({
        name: 'Opción',
        type: PropertyType.NestedModel(Opcion, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _opcion;

    @BasicProperty({
        name: '% Descuento',
        type: PropertyType.Float(-100, 100),
        filters: [RangeFilters, NumericFilter],
        sortable: true
    })
    private _descuento: number = 0;

    @BasicProperty({
        name: 'Importe final',
        type: PropertyType.Float(),
        filters: [RangeFilters, NumericFilter],
        sortable: true
    })
    private _importeFinal: number = 0;

    @BasicProperty({
        name: 'Importe tarifa',
        type: PropertyType.Float(),
        filters: [RangeFilters, NumericFilter],
        sortable: true
    })
    private _importeTarifa: number = 0;

    get descripcion(): string {
        let desc = this._metodoFabricacion.descripcion + ' - ' + this._material.descripcion;
        if (this._trabajo) {
            desc += '  / Trabajo: ' + this._trabajo.descripcion;
        }
        if (this._subTrabajo) {
            desc += ' / Subtrabajo: ' + this._subTrabajo.descripcion;
        }
        if (this._acabado) {
            desc += ' / Acabado: ' + this._acabado.descripcion;
        }
        if (this._opcion) {
            desc += ' / Opción: ' + this._opcion.descripcion;
        }
        desc += ' ---> Descuento: ' + this._descuento + '%';
        return desc;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get cliente() {
        return this._cliente;
    }

    set cliente(value) {
        this._cliente = value;
    }

    get metodoFabricacion(): MetodoFabricacion {
        return this._metodoFabricacion;
    }

    set metodoFabricacion(value: MetodoFabricacion) {
        this._metodoFabricacion = value;
    }

    get material(): Material {
        return this._material;
    }

    set material(value: Material) {
        this._material = value;
    }

    get trabajo() {
        return this._trabajo;
    }

    set trabajo(value) {
        this._trabajo = value;
    }

    get subTrabajo() {
        return this._subTrabajo;
    }

    set subTrabajo(value) {
        this._subTrabajo = value;
    }

    get acabado() {
        return this._acabado;
    }

    set acabado(value) {
        this._acabado = value;
    }

    get opcion() {
        return this._opcion;
    }

    set opcion(value) {
        this._opcion = value;
    }

    get descuento(): number {
        return this._descuento;
    }

    set descuento(value: number) {
        this._descuento = value;
    }

    get importeFinal(): number {
        return this._importeFinal;
    }

    set importeFinal(value: number) {
        this._importeFinal = value;
    }

    get importeTarifa(): number {
        return this._importeTarifa;
    }

    set importeTarifa(value: number) {
        this._importeTarifa = value;
    }
}
