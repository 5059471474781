import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {
    ManagerCachingStrategy,
    PropertyMap,
    SearchType
} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Empleado} from "../personas/empleado.model";
import {UserGroup} from "../personas/user-group.model";
import {IncidenciaAccionPlantilla} from "./incidencia-accion-plantilla.model";

@BasicEntity({
    name: 'Acciones de Incidencias',
    endpoint: 'incidencia_accions',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'descripcion',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'IncidenciaAccion'
})
export class IncidenciaAccion extends Resource {
    static readonly ESTADOS_INCIDENCIA_ACCION = {
        PENDIENTE: 'Pendiente',
        EN_CURSO: "En curso",
        REALIZADA: "Realizada",
        DESCARTADA: "Descartada"
    };

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Descripción de la incidencia',
        type: PropertyType.String()
    })
    private _descripcion;

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(IncidenciaAccion.ESTADOS_INCIDENCIA_ACCION)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _estado;

    @BasicProperty({
        name: 'Marca de tiempo',
        type: PropertyType.DateTime()
    })
    private _marcaTiempo;

    @BasicProperty({
        name: 'Tipo de acción',
        type: PropertyType.NestedModel(IncidenciaAccionPlantilla, null, NestedBehavior.SendIriOnly)
    })
    private _tipoAccion;

    public static readonly MAP_INCIDENCIA: PropertyMap = {
        name: 'Incidencia',
        type: PropertyType.FutureUri('incidencia.model.ts')
    }
    @BasicProperty(IncidenciaAccion.MAP_INCIDENCIA)
    private _incidencia;

    @BasicProperty({
        name: 'Empleado',
        type: PropertyType.NestedModel(Empleado, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _empleado;

    @BasicProperty({
        name: 'Datos',
        type: PropertyType.Text(),
        nullable: true,
        array: true
    })
    private _extraData;

    @BasicProperty({
        name: 'Datos',
        type: PropertyType.Text(),
        nullable: true
    })
    private _resultadoFinal;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }

    get estado() {
        return this._estado;
    }

    set estado(value) {
        this._estado = value;
    }

    get marcaTiempo() {
        return this._marcaTiempo;
    }

    set marcaTiempo(value) {
        this._marcaTiempo = value;
    }

    get tipoAccion() {
        return this._tipoAccion;
    }

    set tipoAccion(value) {
        this._tipoAccion = value;
    }

    get incidencia() {
        return this._incidencia;
    }

    set incidencia(value) {
        this._incidencia = value;
    }

    get empleado() {
        return this._empleado;
    }

    set empleado(value) {
        this._empleado = value;
    }

    get extraData() {
        return this._extraData;
    }

    set extraData(value) {
        this._extraData = value;
    }

    get resultadoFinal() {
        return this._resultadoFinal !== '' ? JSON.parse(this._resultadoFinal) : '';
    }

    set resultadoFinal(value) {
        this._resultadoFinal = value;
    }
}
