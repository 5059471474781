import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {LoteMaterial} from "./lote.model";

@BasicEntity({
    name: 'Tipo materiales',
    endpoint: 'tipo_materials',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    modelNameProperty: 'descripcion',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class TipoMaterial extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id;

    @BasicProperty({
        name: 'Descripción del tipo de material',
        type: PropertyType.String(),
        filters: PartialSearchFilter,
        sortable: true
    })
    private _descripcion;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion(): string {
        return this._descripcion;
    }

    set descripcion(value: string) {
        this._descripcion = value;
    }
}

LoteMaterial.TIPO_MATERIAL_MAP.type = PropertyType.Uri(TipoMaterial)
