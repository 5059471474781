import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from "../../session/session.service";
import {Router, RoutesRecognized} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {SearchService} from "../../services/search.service";
import {Observable} from "rxjs";
import {MatDrawer} from "@angular/material/sidenav";
import {Usuario} from "../../model/personas/usuario.model";
import {Persona} from "../../model/personas/persona.model";
import * as moment from "moment";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    title = '';
    public searchGroup: UntypedFormGroup;
    public displayClock = false;
    public clock = null;
    public profileClock = '';

    @Input() public drawer: MatDrawer;
    @Input() public isHandset$: Observable<boolean>;
    @Input() public menuAvailable: boolean;
    @Input() public mostrarToolBar: boolean;

    public get profileName(): string {
        const profile = this.sessionService.profile;
        if (profile instanceof Usuario) {
            return profile.username;
        } else if (profile instanceof Persona) {
            return profile.nombreCompleto;
        }
        return '';
    }

    public get sessionMinutesLeft(): number {
        return this.sessionService.isLogged() ?
            this.sessionService.token.expiration.diff(moment(), 'm') :
            Infinity;
    }

    private _clockInterval;

    constructor(fb: UntypedFormBuilder,
                public searchService: SearchService,
                public sessionService: SessionService,
                private _router: Router) {
        this._router.events.subscribe(data => {
            if (data instanceof RoutesRecognized) {
                let title = data.state.root.firstChild.data['title'];
                this.displayClock = !!data.state.root.firstChild.data['displayClock'];
                if (title) {
                    this.title = title;
                } else {
                    title = data.state.root.children[0]?.children[0]?.data['title'];
                    if (title) {
                        this.title = title;
                    } else {
                        this.title = '';
                    }
                }
            }
        });

        this.searchGroup = fb.group({search: ''});
        searchService.search.subscribe(s => this.searchGroup.controls['search'].setValue(s, {emitEvent: false}));
        this.searchGroup.controls['search'].valueChanges
            .subscribe(v => searchService.currentSearch = v);
    }

    public logout() {
        this.sessionService.logout();
        this._router.navigateByUrl('/');
        window.location.reload();

    }

    ngOnInit() {
        /* this._clockInterval = setInterval(() => {
             this.clock = moment().format('HH:mm');
             if (this.sessionService.isLogged()) {
                 const diff = this.sessionService.token.expiration.diff(moment(), 's');
                 const duration = moment.duration(diff, 's');
                 let format = 'mm[\'] ss["]';
                 if (duration.asHours() > 1) {
                     format = 'HH[h] mm[\']';
                 }
                 this.profileClock = '- expira en: ' + moment.utc(+duration).format(format);
             } else {
                 this.profileClock = '';
             }
         }, 500);*/
    }

    ngOnDestroy(): void {
        clearInterval(this._clockInterval);
    }
}
