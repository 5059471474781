import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {PropertyMap, ReadWrite} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Material} from "./material.model";

@BasicEntity({
    name: 'MaterialMetodoFabricacionRel',
    endpoint: 'material_metodo_fabricacion_rels',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    modelNameProperty: 'descripcion',
    type: 'MaterialMetodoFabricacionRel'
})
export class MaterialMetodoFabricacionRel extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Material',
        type: PropertyType.NestedModel(Material, null, NestedBehavior.SendIriOnly)
    })
    private _material;

    public static readonly MAP_METODO: PropertyMap = {
        name: 'Método de fabricación',
        type: PropertyType.FutureUri('metodo-fabricacion.model.ts')
    }
    @BasicProperty(MaterialMetodoFabricacionRel.MAP_METODO)
    private _metodoFabricacion;

    @BasicProperty({
        name: 'Carpeta de destino (Validaciones)',
        type: PropertyType.String(),
        nullable: true
    })
    private _carpetaDestino;

    @BasicProperty({
        name: 'Orden',
        type: PropertyType.Integer()
    })
    private _orden: number;

    @BasicProperty({
        name: 'Asignación automática de lote',
        type: PropertyType.Boolean()
    })
    private _asignacionLoteAutomatico = false;

    get descripcion() {
        return this._material.descripcion + ' ---> ' + this._carpetaDestino;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get material() {
        return this._material;
    }

    set material(value) {
        this._material = value;
    }

    get metodoFabricacion() {
        return this._metodoFabricacion;
    }

    set metodoFabricacion(value) {
        this._metodoFabricacion = value;
    }

    get carpetaDestino() {
        return this._carpetaDestino;
    }

    set carpetaDestino(value) {
        this._carpetaDestino = value;
    }

    get orden(): number {
        return this._orden;
    }

    set orden(value: number) {
        this._orden = value;
    }

    get asignacionLoteAutomatico(): boolean {
        return this._asignacionLoteAutomatico;
    }

    set asignacionLoteAutomatico(value: boolean) {
        this._asignacionLoteAutomatico = value;
    }

}
