import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Solicitud} from "./solicitud.model";
import {ArchivoIntraoral} from "../archivos/archivo-intraoral.model";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Solicitud Intraoral',
    endpoint: 'solicitud_intraorals',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    type: 'SolicitudIntraoral',
    managerCaching: ManagerCachingStrategy.Lazy
})

export class SolicitudIntraoral extends Solicitud {

    @BasicProperty({
        name: 'Archivo intraoral',
        type: PropertyType.NestedModel(ArchivoIntraoral, 'solicitud', NestedBehavior.SendIriOnly),
        array: true
    })
    private _archivos: ArchivoIntraoral[] = [];

    get archivos(): ArchivoIntraoral[] {
        return this._archivos;
    }

    set archivos(value: ArchivoIntraoral[]) {
        this._archivos = value;
    }
}

ArchivoIntraoral.SOLICITUD_MAP.type = PropertyType.Uri(SolicitudIntraoral)
