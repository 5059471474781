import {Resource} from "../../api/resource";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {Material} from "../materiales/material.model";
import {Trabajo} from "./trabajo.model";
import {SubTrabajo} from "./subtrabajo.model";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {TipoMaterial} from "../materiales/tipo-material.model";
import {TarifaOpcion} from "./tarifa-opcion.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {TarifaTipoMaterial} from "./tarifa-tipo-material.model";

@BasicEntity({
    name: 'Tarifa',
    endpoint: "tarifas",
    isPaginated: true,
    paginationSizes: [30, 50, 100],
    type: 'Tarifa',
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'resumen'
})
export class Tarifa extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Método',
        type: PropertyType.NestedModel(MetodoFabricacion, null, NestedBehavior.SendIriOnly),
        sortable: true,
        paramName: "metodoFabricacion"
    })
    private _metodoFabricacion: MetodoFabricacion;

    @BasicProperty({
        name: 'Material',
        type: PropertyType.NestedModel(Material, null, NestedBehavior.SendIriOnly),
        sortable: true,
        paramName: "material"
    })
    private _material: Material;

    @BasicProperty({
        name: 'Acabado',
        type: PropertyType.NestedModel(TipoMaterial, null, NestedBehavior.SendIriOnly),
        nullable: true,
        sortable: true,
        paramName: "acabado"
    })
    private _acabado?: TipoMaterial;

    @BasicProperty({
        name: 'Tarifas acabados',
        type: PropertyType.NestedModel(TarifaTipoMaterial, 'tarifa', NestedBehavior.CreateOnly),
        array: true
    })
    private _tarifasAcabado?: TarifaTipoMaterial[];

    @BasicProperty({
        name: 'Trabajo',
        type: PropertyType.NestedModel(Trabajo, null, NestedBehavior.SendIriOnly),
        nullable: true,
        sortable: true,
        paramName: "trabajo"
    })
    private _trabajo?: Trabajo;

    @BasicProperty({
        name: 'SubTrabajo',
        type: PropertyType.NestedModel(SubTrabajo, null, NestedBehavior.SendIriOnly),
        nullable: true,
        sortable: true,
        paramName: "subTrabajo"
    })
    private _subTrabajo?: SubTrabajo;

    @BasicProperty({
        name: 'Opciones',
        type: PropertyType.NestedModel(TarifaOpcion, 'tarifa', NestedBehavior.CreateOnly),
        array: true
    })
    private _opciones?: TarifaOpcion[];

    @BasicProperty({
        name: 'Importe Unitario',
        type: PropertyType.Financial(2, 0, 1000),
        sortable: true
    })
    private _importeUnitario: number;

    @BasicProperty({
        name: 'Vigente',
        type: PropertyType.Boolean()
    })
    private _vigente: boolean = true;

    @BasicProperty({
        name: 'Seleccionar tipo de conexión',
        type: PropertyType.Boolean()
    })
    private _mostrarTiposConexion: boolean = false;

    resumen(): string {
        return `${this.metodoFabricacion} - ${this.material} - ${this.trabajo ?? ""} - ${this.subTrabajo ?? ""}`
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get metodoFabricacion(): MetodoFabricacion {
        return this._metodoFabricacion;
    }

    set metodoFabricacion(value: MetodoFabricacion) {
        this._metodoFabricacion = value;
    }

    get material(): Material {
        return this._material;
    }

    set material(value: Material) {
        this._material = value;
    }

    get acabado(): TipoMaterial {
        return this._acabado;
    }

    set acabado(value: TipoMaterial) {
        this._acabado = value;
    }

    get trabajo(): Trabajo {
        return this._trabajo;
    }

    set trabajo(value: Trabajo) {
        this._trabajo = value;
    }

    get subTrabajo(): SubTrabajo {
        return this._subTrabajo;
    }

    set subTrabajo(value: SubTrabajo) {
        this._subTrabajo = value;
    }

    get opciones(): TarifaOpcion[] {
        return this._opciones;
    }

    set opciones(value: TarifaOpcion[]) {
        this._opciones = value;
    }

    get importeUnitario(): number {
        return this._importeUnitario;
    }

    set importeUnitario(value: number) {
        this._importeUnitario = value;
    }

    get vigente(): boolean {
        return this._vigente;
    }

    set vigente(value: boolean) {
        this._vigente = value;
    }

    get tarifasAcabado(): TarifaTipoMaterial[] {
        return this._tarifasAcabado;
    }

    set tarifasAcabado(value: TarifaTipoMaterial[]) {
        this._tarifasAcabado = value;
    }

    get mostrarTiposConexion(): boolean {
        return this._mostrarTiposConexion;
    }

    set mostrarTiposConexion(value: boolean) {
        this._mostrarTiposConexion = value;
    }
}

TarifaOpcion.TARIFA_MAP.type = PropertyType.Uri(Tarifa);
TarifaTipoMaterial.TARIFA_MAP.type = PropertyType.Uri(Tarifa);
