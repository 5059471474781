import {Caster} from "../casters/caster";
import {FilterDefinition, FilterGroup, ObjectPropertyMap, PropertyMap, ReadWrite, SearchType} from "./mapping-external";
import {CompoundCaster} from "../casters/compound-caster";
import {Filter} from "../../api/filter-interface";
import {
    ExactSearchFilter,
    PartialSearchFilter,
    StartSearchFilter,
    WordStartSearchFilter
} from "../filters/search-filter";
import {BooleanFilter} from "../filters/boolean-filter";
import {DateFilters} from "../filters/date-filter";
import {TypeStr} from "../property-type/type-str";
import {PropertyTypeInterface} from "../property-type/property-type-interface";
import {InterfaceProviderService} from "../services/interface-provider.service";

export const IRI_PROPERTY = 'iri';
export const TYPE_PROPERTY = 'entityType';

const ReadWriteOrExclude: { [s: string]: ReadWriteOrExcludeType } = {
    ReadWrite: {read: true, write: true},
    ReadOnly: {read: true, write: false},
    WriteOnly: {read: false, write: true},
    Exclude: {read: false, write: false}
};

export interface ReadWriteOrExcludeType {
    read: boolean;
    write: boolean;
}

/**
 * Interface to map a property for any object
 */
export class InternalObjectPropertyMap {
    /** Key of this property in the model */
    public readonly modelKey: string;
    /** Key for the property in the map answers or requests */
    public readonly key: string;
    /** Indicates that the property can be null */
    public readonly nullable: boolean;
    /**
     * The caster resulting of compounding the pre and pos casters,
     */
    private _caster: Caster<any, any> | null = null;
    public get caster(): Caster<any, any> | null {
        return this._caster;
    }

    private _casterInitialised = false;
    /**
     * A name to display the property (in tables, edition, etc)
     */
    public readonly name: string;
    /**
     * Indicates if the property should be read from/written to the model.
     */
    public readonly readWrite: ReadWrite;
    /**
     * Indicates if the property should be read from/written to the API.
     * Properties which are read-only will never be sent in insertions or updates.
     * Properties which are write-only will never be fetched from the API.
     */
    public readonly apiBehavior: ReadWriteOrExcludeType;
    /**
     * The type of attribute for this property, important to make sure it is displayed right in the BasicEntityTableComponent
     */
    public readonly type: PropertyTypeInterface;
    /** Indicates whether this property is an array or not (of the given type) */
    public readonly array: boolean;
    /** Order in dialog **/
    public readonly order: number;

    private readonly _preCaster;
    private readonly _posCaster;

    constructor(modelKey: string, mapping: ObjectPropertyMap, readOnlyInterface: boolean = false, isId: boolean = false) {
        this.modelKey = modelKey;
        this.key = mapping.keyInApi || modelKey;
        this.nullable = !!mapping.nullable;
        this.name = mapping.name || this.key;
        this.type = mapping.type;
        this.array = !!mapping.array;
        this.order = mapping.order ? mapping.order : 0;


        this.readWrite = readOnlyInterface ? ReadWrite.ReadOnly :
            (isId ? ReadWrite.ReadWrite : (mapping.readWrite || ReadWrite.ReadWrite));

        // this.apiBehavior:
        const dontReadFromApi = mapping.dontReadFromApi === true;
        if (this.readWrite === ReadWrite.ReadOnly) {
            this.apiBehavior = dontReadFromApi ? ReadWriteOrExclude.Exclude : ReadWriteOrExclude.ReadOnly;
        } else if (this.readWrite === ReadWrite.WriteOnly) {
            this.apiBehavior = ReadWriteOrExclude.WriteOnly;
        } else {
            this.apiBehavior = dontReadFromApi ? ReadWriteOrExclude.WriteOnly : ReadWriteOrExclude.ReadWrite;
        }

        this._preCaster = mapping.preCaster;
        this._posCaster = mapping.posCaster;
    }

    public initialiseCasters(interfaceProvider: InterfaceProviderService,
                             caster: Caster<any, any> | null,
                             stringCaster: Caster<any, any> | null) {
        if (this._casterInitialised) {
            return;
        }
        this._casterInitialised = true;

        // May seem the following casters are reversed, but they are not
        // because the definition of caster thinks the flow from the model to the API,
        // while the names preCaster and posCaster are thought from the API to the model
        if (this._posCaster) {
            caster = caster ? new CompoundCaster(this._posCaster, caster) : this._posCaster;
        }
        if (this._preCaster) {
            caster = caster ? new CompoundCaster(caster, this._preCaster) : this._preCaster;
        }
        this._caster = caster;

        if (stringCaster) {
            this.type.stringCaster = stringCaster;
        }
    }
}

/**
 * Interface to map a property from the API to the model for the BasicEntityInterface to use.
 * This is an internal mapping generated from the external one by the BasicEntityInterface on construction
 */
export class InternalPropertyMap extends InternalObjectPropertyMap {
    /** Whether the entity can be sorted by this column or not */
    public readonly sortable: boolean;
    /** Param to send to the API to search by this parameter, if it is not present the interface will try to elaborate it*/
    public readonly paramName: string;

    /** Filters that can be applied to the property */
    public get filters(): Filter[] {
        return this._flat(this.filtersUnflatted);
    };

    /** Filters and filter groups just as they were specified originally in the model */
    public readonly filtersUnflatted: (Filter | FilterGroup)[];

    /**
     * Indicates the property should be editable only when the item is being created
     */
    public readonly onlyOnNew: boolean;
    /** Indicates if the property is an id */
    public readonly isId: boolean;
    /** The tag of the string, tags allow to specify that a group of properties are related between them */
    public readonly tag: string;
    /** The value of this property is irrelevant if the value of the indicated property evaluates to false.
     * If this is "null" then this property does not depend on anything. */
    public readonly dependsOn: string | null;

    public readonly dependsOnContrary: boolean | null;

    public readonly fixedFilter: boolean | null;

    constructor(modelKey: string, mapping: PropertyMap, readOnlyInterface: boolean) {
        super(modelKey, mapping, readOnlyInterface, !!mapping.isId);
        this.isId = !!mapping.isId;
        this.sortable = !!mapping.sortable;
        this.paramName = mapping.paramName || this.key;
        this.onlyOnNew = !!mapping.onlyOnNew;
        this.dependsOn = mapping.dependsOn || null;
        this.dependsOnContrary = mapping.dependsOnContrary;
        this.tag = mapping.tag ? mapping.tag : 'Principal';
        this.fixedFilter = mapping.fixedFilter || null;

        if (mapping.filters) {
            this.filtersUnflatted = InternalPropertyMap._sMakeArray(mapping.filters);
        } else {
            this.filtersUnflatted = [];
        }

        if (mapping.searchable) {
            this.filtersUnflatted = this.filtersUnflatted.concat(this._filterForLegacy(mapping.searchable));
        }
    }

    private static _sMakeArray(filters: FilterDefinition | FilterDefinition[]): (Filter | FilterGroup)[] {
        if (Array.isArray(filters)) {
            return filters;
        } else {
            return [filters];
        }
    }

    private _flat(filters: FilterDefinition | FilterDefinition[]): Filter[] {
        if (Array.isArray(filters)) {
            return (filters as FilterDefinition[])
                .map((filterOrGroup: FilterDefinition) => {
                    if (Array.isArray(filterOrGroup)) {
                        return filterOrGroup as Filter[];
                    } else {
                        return [filterOrGroup] as Filter[];
                    }
                })
                .reduce<Filter[]>((p, c) => p.concat(c), []);
        } else {
            return [filters];
        }
    }

    private _filterForLegacy(searchType: SearchType): Filter[] {
        switch (searchType) {
            case SearchType.Exact:
                if ([TypeStr.DateTime, TypeStr.Date, TypeStr.Time].includes(this.type.toString())) {
                    return DateFilters; // We used to mark date filtering this way
                } else {
                    return [ExactSearchFilter];
                }
            case SearchType.Partial:
                return [PartialSearchFilter];
            case SearchType.Start:
                return [StartSearchFilter];
            case SearchType.End:
            case SearchType.WordStart:
                return [WordStartSearchFilter];
            case SearchType.Boolean:
                return [BooleanFilter];
        }
    }
}

export interface InterfaceMapping {
    [s: string]: InternalPropertyMap;
}
