<mat-sidenav-container [ngClass]="!isLogged() ? 'sidenav-container-loggin' : 'sidenav-container'">
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async)   ? 'over' : 'side'"
                 [opened]="menuItems && menuItems.length > 0">
        <app-sidenavHeader></app-sidenavHeader>
        <app-menu [items]="menuItems" (itemClicked$)="menuItemClicked(drawer)"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <header>
            <app-header [drawer]="drawer" [menuAvailable]="menuItems && menuItems.length > 0"
                        [isHandset$]="isHandset$"></app-header>
        </header>
        <main [ngClass]="!isLogged() ? 'main-loggin' : 'main'">
            <router-outlet></router-outlet>
        </main>
        <footer>
            <app-footer *ngIf="isLogged()"></app-footer>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>
<div class="cargando_overlay" *ngIf="mostrarCargando && cargando && isLogged()">
    <div>CARGANDO DATOS. POR FAVOR, ESPERE ...</div>
</div>
