import {Component, Input, OnInit, Output, Type, ViewChild} from '@angular/core';
import {EntityDatasource} from '../../api/entity.datasource';
import {MatDialog} from '@angular/material/dialog';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {Caster} from '../../basic-entity-back/casters/caster';
import {EditionDialogComponent} from '../edition-dialog/edition-dialog.component';
import {BasicEntityInterface} from '../../basic-entity-back/basic-entity-interface/basic-entity-interface';
import {TypeStr} from '../../basic-entity-back/property-type/type-str';
import {Resource} from '../../api/resource';
import {ComponentType} from '@angular/cdk/portal';
import {BaseDialog} from './base-dialog';
import {InterfaceProviderService} from '../../basic-entity-back/services/interface-provider.service';
import {BaseDialogData} from './base-dialog-data';
import {FilterDefinition, ReadWrite} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Uri} from '../../api/uri';
import {EntityNameService} from '../../basic-entity-back/services/entity-name.service';
import {ApiModuleFactory} from '../../api/api-module-factory.service';
import {SimpleDialogService} from '../dialog-shell/simple-dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorDisplayService} from '../services/error-display.service';
import {InternalPropertyMap, IRI_PROPERTY} from '../../basic-entity-back/basic-entity-interface/mapping-internal';
import {PropertyTypeInterface} from '../../basic-entity-back/property-type/property-type-interface';
import {BasicEntityFilteringComponent} from '../basic-entity-filtering/basic-entity-filtering.component';
import {BasicEntityTableConstants} from './basic-entity-table-constants';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {fadeInFadeOut} from '../animations/animations';
import {BehaviorSubject, Subject} from 'rxjs';
import {BloqueadorService} from "../../services/bloqueador.service";
import {BaseEntity} from "../../model/base-entity.model";

export interface BasicEntityTableColumnStyle {
    /** Column displayed name */
    colName?: string;
    /** Classes to set to both header and cells of the column */
    extraClass?: string;
    /** Weight of the column (to distribute spatially respect to the other ones) */
    weight?: number;
    /** The justification to show the column in the table, by default LEFT will be applied */
    justification?: ColumnJustification;
    /** Show data ss button */
    shownAsButton?: boolean;
    /** Button data */
    buttonData?: { text: (T) => {}, action: (T) => {}, class: (T) => {}, disabled: (T) => {}, isLink?: boolean };
}

/**
 * Description of a column to display in the BasicEntityTable
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export interface BasicEntityTableColumnDescription extends BasicEntityTableColumnStyle {
    /** Key of the property in the model to populate the column (or just the id of the column if it is an action column) */
    key: string;
    /** Set to true to hide the column in the table (it will be still editable) */
    hidden?: boolean;
    /** Set to true to hide the column in the edition dialog (it will be still visible in the table)*/
    hiddenInEdition?: boolean;
    /** Indicates the column is hidden in the table but it could be displayed if the user wishes so (if it is used hidden turns irrelevant) */
    hiddenDisplayable?: boolean;

}

export type BasicEntityTableColumnDescriptionOrString = BasicEntityTableColumnDescription | string;

/**
 * BasicEntityTable, automatically manages and displays models with an available BasicEntityInterface.
 * This is the main element of the whole BasicEntityBackModule.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Component({
    selector: 'be-table',
    templateUrl: './basic-entity-table.component.html',
    styleUrls: ['./basic-entity-table.component.scss'],
    animations: [fadeInFadeOut]
})
export class BasicEntityTableComponent<T extends Resource> implements OnInit {
    public static readonly PAGINATION_PAGE =
        BasicEntityTableConstants.PAGINATION_PAGE;
    public static readonly PAGINATION_SIZE =
        BasicEntityTableConstants.PAGINATION_SIZE;

    public readonly ACTIONS_COLUMN = '$actions';

    /** Little hack to make TypeStr available to the template */
    protected TypeStr = TypeStr;

    /** ID to identify the table so we can save the table configuration! */
    @Input() public id: string = null;
    /** A model to search for the interface */
    @Input() public modelType: Type<Resource> = null;
    /** Decription of the properties we want to show (and edit) */
    @Input()
    public columnsToDisplay: BasicEntityTableColumnDescriptionOrString[] = [];
    /** Actions for the table rows, they can perform any kind of action over a model element */
    @Input() public actions: {
        text: string;
        tooltip?: string;
        class?: string;
        disabled?: (model: T) => boolean;
        handler: (model: T) => void;
        icono?: string;
    }[] = [];

    /** Indica si hay que marcar un aviso para esa columna*/
    @Input() public avisos?: {
        tieneAviso?: (model: T) => boolean
    }[] = [];

    /** Actions column style */
    @Input() public actionsColumnStyle: BasicEntityTableColumnStyle = {};
    /** Edition dialog to use, if none provided it will use the default dialog component */
    @Input() public editionDialog: ComponentType<BaseDialog<T>> = EditionDialogComponent;
    /** Creation dialog to use, if none provided we will use the editionDialog for this*/
    @Input() public creationDialog: ComponentType<BaseDialog<T>> | null = null;
    /** Initial sorting for the table */
    @Input() public initialSorting: {
        active: string;
        direction: 'asc' | 'desc' | '';
    } = null;
    /** Image to display when the table is empty */
    @Input() public imageOnEmpty: { url: string; alt: string } | null = null;
    /** Text to display when the table is empty */
    @Input() public textOnEmpty = '';
    @Input() public allowCreation = true;
    @Input() public allowEdition = true;
    @Input() public allowRemoval = true;

    /** Change this to change the behavior of clicking an item */
    @Input() public onElementClick: (
        row: T,
        column: BasicEntityTableColumn
    ) => void = (row, column) => {
        if (!column.shownAsButton) {
            this.editModel(row);
        }
        this.clickRowColumn.next([row, column]);
    }
    @Input() public reloadDataSource: BehaviorSubject<boolean>;
    @Output() clickRowColumn: Subject<[T, BasicEntityTableColumn]> = new Subject<[T, BasicEntityTableColumn]>();

    @Input() public reloadItem: Subject<T>;
    /** Paginator component (check template) */
    @ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;
    /** Sort component in the headers of the table (check template) */
    @ViewChild(MatSort) protected sort: MatSort;
    /** Material table (check template) */
    @ViewChild(MatTable) protected table: MatTable<any>;
    /** Context menu of the table (check template) */
    @ViewChild('menu') protected menu: MatMenu;
    /** Trigger for the context menu (allows us to control the menu) */
    @ViewChild('menuTrigger')
    protected menuTrigger: MatMenuTrigger;
    /** The filtering component */
    @ViewChild(BasicEntityFilteringComponent) public entityFiltering: BasicEntityFilteringComponent<T>;

    /** DataSource which will manage the data displayed in the table */
    public dataSource: EntityDatasource<T>;
    /** Columns to display, generated from the properties description and the interface of the entity */
    public columns: BasicEntityTableColumn[];
    /** Whether we should show the paginator or not */
    public showPaginator = true;
    /** Column which might be displayed if we want (to allow activation an deactivation) */
    public displayableColumns: BasicEntityTableColumn[];
    /** Columns actually displayed (needed by the MatTable) */
    public displayedColumns: string[];
    /** Left value in the style of the menu (to position the menu where we click) */
    public menuLeft;
    /** Top value in the style of the context menu (to position it where we click) */
    public menuTop;

    /** Row the menu is currently editing */
    protected menuRow: T = null;

    /** The interface of the entity to display, this input is required */
    private _entityInterface: BasicEntityInterface<T>;


    /** Indica si hay que marcar un aviso para esa columna */
    @Input() public rowClass?: {
        class?: (model: T) => string
    }[] = [];

    public get propertiesFromColumns(): InternalPropertyMap[] {
        return this.columns.map(c => c.original);
    }

    /** Whether the paginator should show "go to first" and "go to last" buttons */
    public get showFirstLastButtons(): boolean {
        if (this.dataSource.pagination.pageSize) {
            return (
                this.dataSource.totalItems /
                this.dataSource.pagination.pageSize >
                3
            );
        } else {
            return false;
        }
    }

    constructor(
        private _interfaceProvider: InterfaceProviderService,
        private _apiFactory: ApiModuleFactory,
        private _nameService: EntityNameService,
        private _dialogService: MatDialog,
        private _confirmationDlg: SimpleDialogService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _errorDisplay: ErrorDisplayService,
        public _bloqueador: BloqueadorService
    ) {
    }

    ngOnInit() {
        // Siempre asigno una id a la tabla
        this.id = this.modelType.name.toLowerCase();

        if (!this.modelType) {
            throw new Error('Model type requerido!');
        }
        this._entityInterface = this._interfaceProvider.interfaceForModel(
            this.modelType
        ) as BasicEntityInterface<T>;
        if (this._entityInterface.readonlyInterface) {
            this.allowCreation = false;
            this.allowRemoval = false;
        }
        this._createDatasource();
        this._elaborateColumns();
        this._initialisePrecachingOfUris();
        this._elaborateDisplayedColumns();
        this._initPagination();
        this._initSorting();
        if (this.reloadDataSource !== undefined) {
            this.reloadDataSource.subscribe(valor => {
                if (valor === true) {
                    this.dataSource.load();
                    this.reloadDataSource.next(false);
                }
            });
        }

        if (this.reloadItem !== undefined) {
            this.reloadItem.subscribe(valor => {
                if (valor.entityType === undefined) {
                    this.dataSource.load();
                } else {
                    this.dataSource.updateItem(valor);
                }
            });
        }
    }

    /**
     * Creates the DataSource which will provide the data to the table
     * using the entity interface and the injected ApiService
     * @private
     */
    private _createDatasource() {
        this.dataSource = this._apiFactory.createDataSource(
            this._entityInterface
        );
    }

    /**
     * Sets up a subscription to the changes in the entities provided by the entity data source
     * so we can pre-cache the URIs for the items all in bulk instead of one by one.
     * @private
     */
    private _initialisePrecachingOfUris() {
        const uriProperties = [];
        for (const [property, map] of Object.entries(
            this._entityInterface.mappingModelToApi
        )) {
            if (
                map.type.toString() === TypeStr.Uri &&
                property !== IRI_PROPERTY
            ) {
                uriProperties.push(property);
            }
        }
        this.dataSource.entities$.subscribe(entities => {
            if (!entities || entities.length === 0) {
                return;
            }

            const uris: { [pattern: string]: Uri[] } = {};
            for (const property of uriProperties) {
                for (const entity of entities) {
                    const val = this._entityInterface.serialiser.getValue(
                        entity,
                        property
                    );
                    const uriArray = this._entityInterface.mappingModelToApi[
                        property
                        ].array
                        ? val
                        : [val];
                    if (uriArray) {
                        for (const uri of uriArray) {
                            if (uri) {
                                if (!uris[uri.pattern]) {
                                    uris[uri.pattern] = [];
                                }
                                if (
                                    !uris[uri.pattern].find(
                                        next =>
                                            next.toString() === uri.toString()
                                    )
                                ) {
                                    uris[uri.pattern].push(uri);
                                }
                            }
                        }
                    }
                }
            }

            for (const urisWithSamePattern of Object.values(uris)) {
                this._nameService.bulkCacheUpdate(urisWithSamePattern, false);
            }
        });
    }

    /**
     * Elaborates the properties field mixing the information from the entity interface (the mapping)
     * and the introduced properties to display.
     * @private
     */
    private _elaborateColumns() {
        this.columns = [];
        const copyEntries = Object.entries(
            this._entityInterface.mappingModelToApi
        );
        // We want to respect the order of the properties description
        for (const colDescriptionOrStr of this.columnsToDisplay) {
            let colDescription: BasicEntityTableColumnDescription;
            if (typeof colDescriptionOrStr === 'string') {
                colDescription = {key: colDescriptionOrStr};
            } else {
                colDescription = colDescriptionOrStr;
            }
            const map = this._entityInterface.mappingModelToApi[
                colDescription.key
                ];
            if (!map) {
                throw new Error(`Unknown column '${colDescription.key}'`);
            }
            this.columns.push(
                this._columnForMapAndDescription(
                    colDescription.key,
                    map,
                    colDescription
                )
            );
            const idx = copyEntries.findIndex(
                ([key]) => key === colDescription.key
            );
            copyEntries.splice(idx, 1);
        }
        // We add at the end the other ones
        for (const [key, map] of copyEntries) {
            this.columns.push(this._columnForMapAndDescription(key, map, null));
        }
    }

    private _columnForMapAndDescription(
        key: string,
        map: InternalPropertyMap,
        colDescription: BasicEntityTableColumnDescription | null
    ): BasicEntityTableColumn {
        return {
            original: map,
            modelKey: key,
            isId: this._entityInterface.isIdProperty(key),
            title: colDescription
                ? colDescription.colName || map.name
                : map.name,
            toStrCaster: map.type.stringCaster,
            justification: colDescription
                ? colDescription.justification || ColumnJustification.Left
                : ColumnJustification.Left,
            ngClass: colDescription ? colDescription.extraClass || '' : '',
            weight: colDescription ? colDescription.weight : 1,
            hiddenInEdition: colDescription
                ? !!colDescription.hiddenInEdition
                : this.columnsToDisplay.length !== 0,
            hiddenInTable: colDescription
                ? !!(colDescription.hidden || colDescription.hiddenDisplayable)
                : this.columnsToDisplay.length !== 0,
            canBeDisplayed: colDescription
                ? !colDescription.hidden || colDescription.hiddenDisplayable
                : false,
            shownAsButton: colDescription
                ? colDescription.shownAsButton : false,
            buttonData: colDescription
                ? colDescription.buttonData : null,
            readWrite: map.readWrite,
            array: map.array,
            sortable: map.sortable,
            nullable: map.nullable,
            type: map.type,
            tag: map.tag
        };
    }

    /**
     * Elaborates the displayedColumns array for the MatTable to know what to show
     * @private
     */
    private _elaborateDisplayedColumns() {
        const columnsPrev: {
            key: string;
            shown: boolean
        }[] | null = this.id ? JSON.parse(localStorage.getItem(this._localStorageDisplayedColumnsKey)) : null;
        const currentDisplayableColumns = this.columns.filter(col => col.canBeDisplayed && col.readWrite !== ReadWrite.WriteOnly);
        if (columnsPrev) {
            // Sort by the old order
            this.displayableColumns = currentDisplayableColumns.sort((a, b) => {
                let indexA = columnsPrev.findIndex(c => c.key === a.modelKey);
                if (indexA < 0) {
                    indexA = columnsPrev.length;
                }
                let indexB = columnsPrev.findIndex(c => c.key === b.modelKey);
                if (indexB < 0) {
                    indexB = columnsPrev.length;
                }
                return indexA - indexB;
            });
        } else {
            this.displayableColumns = currentDisplayableColumns;
        }
        if (columnsPrev) {
            this.displayedColumns = columnsPrev
                .filter(
                    column =>
                        column.shown &&
                        this.displayableColumns.find(
                            displayable => displayable.modelKey === column.key
                        )
                )
                .map(c => c.key);
            if (this.actions.length > 0) {
                this.displayedColumns.push(this.ACTIONS_COLUMN);
            }
        } else {
            this.displayedColumns = this.columns
                .filter(col => !col.hiddenInTable && col.readWrite !== ReadWrite.WriteOnly)
                .map(col => col.modelKey);
            if (this.actions.length > 0) {
                this.displayedColumns.push(this.ACTIONS_COLUMN);
            }
        }
    }

    /**
     * Hides the indicated column in the table display,
     * returns the original position of the column or null if there is no such column displayed
     * @param key
     */
    public hideColumn(key: string): number | null {
        const idx = this.displayedColumns.indexOf(key);
        if (idx >= 0) {
            this.displayedColumns.splice(idx, 1);
            this._saveDisplayedColumns();
            return idx;
        }
        return null;
    }

    /**
     * Shows the column at the specified index, if no index is provided it shows the column where it is positioned in displayableColumns
     * @param key
     * @param idx
     */
    public showColumn(key: string, idx: number = null) {
        const col = this.columns.find(c => c.modelKey === key);
        if (key === this.ACTIONS_COLUMN || (col && col.readWrite !== ReadWrite.WriteOnly)) {
            if (this.displayedColumns.indexOf(key) < 0) {
                if (idx === null) {
                    if (key === this.ACTIONS_COLUMN) {
                        idx = this.displayedColumns.length;
                    } else {
                        idx = 0;
                        for (const col of this.displayableColumns) {
                            if (col.modelKey === key) {
                                break;
                            }
                            if (
                                this.displayedColumns.indexOf(col.modelKey) >= 0
                            ) {
                                idx++;
                            }
                        }
                    }
                }
                this.displayedColumns.splice(idx, 0, key);
                this._saveDisplayedColumns();
            }
        }
    }

    public toggleColumn(key: string, event: Event) {
        event.stopPropagation();
        if (this.hideColumn(key) === null) {
            this.showColumn(key);
        }
    }

    public filterOrGroupRepr(filter: FilterDefinition) {
        return BasicEntityFilteringComponent.sFilterOrGroupRepr(filter);
    }

    /**
     * Initialises the pagination of the table
     * @private
     */
    private _initPagination() {
        this.showPaginator = this._entityInterface.isPaginated;
        this.paginator.pageSizeOptions = this._entityInterface.paginationSizes;
        // Listen pagination from the url
        this._activatedRoute.queryParams.subscribe(params => {
            if (
                BasicEntityTableComponent.PAGINATION_PAGE in params &&
                !isNaN(params[BasicEntityTableComponent.PAGINATION_PAGE])
            ) {
                this.paginator.pageIndex = parseInt(
                    params[BasicEntityTableComponent.PAGINATION_PAGE],
                    10
                );
            } else {
                this.paginator.pageIndex = 0;
            }

            if (
                BasicEntityTableComponent.PAGINATION_SIZE in params &&
                !isNaN(params[BasicEntityTableComponent.PAGINATION_SIZE])
            ) {
                this.paginator.pageSize = parseInt(
                    params[BasicEntityTableComponent.PAGINATION_SIZE],
                    10
                );
            } else if (this.paginator.pageSizeOptions.length > 0) {
                this.paginator.pageSize = this.paginator.pageSizeOptions[0];
            }

            this.dataSource.pagination = {
                pageSize: this.paginator.pageSize,
                pageIndex: this.paginator.pageIndex
            };
        });
        // Listen pagination from the server
        this.dataSource.serverPagination.subscribe(newPagination => {
            if (newPagination) {
                this.paginator.pageIndex = newPagination.pageIndex || 0;
                this.paginator.pageSize = newPagination.pageSize || 20;
            }
        });
    }

    public pageChange(event: PageEvent) {
        this._router.navigate([], {
            queryParams: {
                [BasicEntityTableComponent.PAGINATION_PAGE]: event.pageIndex,
                [BasicEntityTableComponent.PAGINATION_SIZE]: event.pageSize
            },
            queryParamsHandling: 'merge'
        });
    }

    private _initSorting() {
        if (this.initialSorting) {
            this.dataSource.sorting = this.initialSorting;
        }
    }

    public addNewRow() {
        const model: T = this._entityInterface.serialiser.getEmptyModel();
        this.editModel(model, true);
    }

    /**
     * Gets a value for a column from the model
     */
    public modelValue(model: T, column: BasicEntityTableColumn): any {
        return model[column.modelKey];
    }

    /**
     * Executed when an action button is clicked. Simply calls the action on the model.
     */
    public actionClick(action: { text: string; handler: (model: T) => void }, model: T) {
        action.handler(model);
    }

    public clase(model) {
        if (this.rowClass && this.rowClass.length > 0) {
            return this.rowClass[0].class(model);
        } else {
            return '';
        }
    }

    public hide(index, model) {
        console.log(index, model);
        return this.actions[index].disabled(model);
    }

    public iconoOTexto(action: Object): string {
        return (action['icono']) ? action['icono'] : '';
    }

    public conIconos(): boolean {
        return !!this.actions[0].icono;
    }

    /**
     * Opens the context menu on the position clicked by the mouse to edit
     * the indicated row.
     */
    public openContextMenu(event: MouseEvent, row: T) {
        this.menuLeft = event.clientX;
        this.menuTop = event.clientY;
        this.assignRowToContextMenu(row);
        // Timeout needed to avoid update on the same JavaScript block
        setTimeout(this.menuTrigger.openMenu.bind(this.menuTrigger));
        return false;
    }

    /**
     * Assigns the given row to the context menu (for actions to perform adequately)
     */
    public assignRowToContextMenu(row) {
        this.menuRow = row;
    }

    /**
     * When we click on an action in the context menu, this function is executed.
     * It delegates into actionClick
     */
    public contextActionClick(action) {
        if (this.menuRow) {
            this.actionClick(action, this.menuRow);
        }
    }

    /**
     * Determines whether a button in the context menu for an action should
     * be disabled or not.
     * @param action
     */
    public contextActionDisabled(action: any) {
        if (this.menuRow) {
            return action.disabled ? action.disabled(this.menuRow) : false;
        }
        return true;
    }

    /**
     * When we click edit on the context menu, it delegates on editModel over the
     * row currently associated to the context menu
     */
    public contextEdit() {
        if (this.menuRow) {
            this.editModel(this.menuRow);
        }
    }

    /**
     * Edits the model by opening the correct dialog editor
     */
    protected editModel(model: T, isNew: boolean = false) {
        if ((!this.allowEdition && !isNew) || (!this.allowCreation && isNew)) {
            return;
        }

        if (model instanceof BaseEntity) {
            this._bloqueador.bloquear(model).subscribe(res => {
                if (!!res) {
                    this._edit(res, false);
                }
            });
        } else {
            this._edit(model, isNew);
        }
    }

    protected _edit(model: T, isNew: boolean = false) {
        this.dataSource.load();

        const ref = this._dialogService.open<BaseDialog<T>, BaseDialogData<T>, any>
        (isNew && this.creationDialog ? this.creationDialog : this.editionDialog,
            {
                disableClose: !this._entityInterface.readonlyInterface,
                autoFocus: true,
                restoreFocus: true,
                data: {
                    model: model,
                    allowIdEdition: isNew && !this._entityInterface.autogeneratedId,
                    columns: this.columns.filter(col => !col.hiddenInEdition).map(col => col.original),
                    showSaveAndCancelButtons: !this._entityInterface.readonlyInterface,
                    isNew: isNew,
                    shouldManageSaving: true
                }
            }
        );

        if (!this._entityInterface.readonlyInterface) {
            ref.afterClosed().subscribe(result => {
                if (result === EditionDialogComponent.RESULT_SHOULD_RELOAD) {
                    this.dataSource.load();
                }
            });
        }
    }


    /**
     * Called when clicking delete in the context menu
     */
    contextDelete() {
        const name = this._entityInterface.getName(this.menuRow);
        this._confirmationDlg
            .confirmationDialog(
                'Eliminar',
                `¿Seguro que deseas eliminar <strong>${name}</strong>?`,
                ['Eliminar', 'delete_forever', 'warn']
            )
            .then(del => del && this.acceptDeletion());
    }

    /** Close the dialog to confirm a deletion and delete the item */
    acceptDeletion() {
        if (!this.allowRemoval) {
            return;
        }

        this._interfaceProvider
            .managerForModel(this.modelType)
            .remove(this.menuRow)
            .subscribe(
                () => this.dataSource.load(),
                err => this._errorDisplay.displayError(err)
            );
    }

    public tableConfigDrop(event: CdkDragDrop<BasicEntityTableColumn[]>) {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        const item = this.displayableColumns[event.previousIndex];
        const index = this.displayedColumns.indexOf(item.modelKey);
        let offset = 0;
        // If the column was displayed, we check in the displayed ones the offset we have to move it
        if (index >= 0) {
            const increment = event.previousIndex < event.currentIndex ? 1 : -1;
            const startCount = event.previousIndex + increment;
            const endCount = event.currentIndex + increment;
            for (let i = startCount; i !== endCount; i += increment) {
                if (
                    this.displayedColumns.includes(
                        this.displayableColumns[i].modelKey
                    )
                ) {
                    offset += 1 * increment;
                }
            }
        }
        moveItemInArray(
            this.displayableColumns,
            event.previousIndex,
            event.currentIndex
        );
        if (index >= 0) {
            moveItemInArray(this.displayedColumns, index, index + offset);
        }
        this._saveDisplayedColumns();
    }

    private _saveDisplayedColumns(): void {
        if (this.id) {
            localStorage.setItem(
                this._localStorageDisplayedColumnsKey,
                JSON.stringify(
                    this.displayableColumns.map(c => ({
                        key: c.modelKey,
                        shown: this.displayedColumns.includes(c.modelKey)
                    }))
                )
            );
        }
    }

    private get _localStorageDisplayedColumnsKey(): string {
        return `ndw-tables-${this.id}`;
    }

    public trackByModelKey(index, column: BasicEntityTableColumn) {
        return column.modelKey;
    }

    public trackByIri = Resource.sTrackByIri;
    mostrarFiltrado: boolean;
}

/**
 * Interface which describes the properties the table is displaying,
 * is used internally along the components that the table uses
 * to display and edit the information correctly.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export interface BasicEntityTableColumn {
    /** Key in the model of the property to get and set the value of the column */
    modelKey: string;
    /** Determines if this column corresponds to an id property or not */
    isId: boolean;
    /** Title of the column to display */
    title: string;
    /** Caster to string used by the table to display correctly some types. Do not mistake with the caster in the BasicEntityInterface. */
    toStrCaster?: Caster<any, string>;
    /** Some extra HTML classes to be added to both the header and body cells of the table for this column */
    ngClass: string;
    /** Justification of the display of this column */
    justification: ColumnJustification;
    /** The weight of the column to dimension it spatially respect to the others */
    weight: number;
    /** Whether the column correspond to an array of items or a single item */
    array: boolean;
    /** Whether the items can be sorted by this column or not (depends on the API) */
    sortable: boolean;
    /** Whether this column should be hidden in table */
    hiddenInTable: boolean;
    /** The column is selectable to be displayed */
    canBeDisplayed: boolean;
    /** Whether this column should be hidden in edition */
    hiddenInEdition: boolean;
    /** Whether this column is nullable (accepts null values) or not */
    nullable: boolean;
    /** The type of the column, directly taken from the property description in the EntityInterface */
    type: PropertyTypeInterface;
    /** Indicates if the property should be read from/written to the API */
    readWrite: ReadWrite;
    /** Tag of the column */
    tag: string;
    /** Property of origin */
    original: InternalPropertyMap;
    /** Show data ss button */
    shownAsButton?: boolean;
    /** Button data */
    buttonData?: {
        text: (T) => {},
        action: (T) => {},
        class: (T) => {},
        disabled: (T) => {},
        color?: (T) => {},
        isLink?: boolean
    };
}

export enum ColumnJustification {
    Left = 'flex-start',
    Center = 'center',
    Right = 'flex-end'
}
