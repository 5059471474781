import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {TarifaOpcion} from "../trabajos/tarifa-opcion.model";
import {Opcion} from "../trabajos/opcion.model";
import {Tornillo} from "../almacen/tornillo.model";

@BasicEntity({
    name: 'Tornillo Linea Pedido',
    endpoint: 'linea_pedido_tornillos',
    isPaginated: true
})
export class LineaPedidoTornillo extends Resource {

    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    public static readonly MAP_LINEA_PEDIDO: PropertyMap = {
        name: 'Linea Pedido',
        type: PropertyType.FutureUri('linea-pedido.model.ts')
    }
    @BasicProperty(LineaPedidoTornillo.MAP_LINEA_PEDIDO)
    private _lineaPedido;

    @BasicProperty({
        name: 'Tornillo',
        type: PropertyType.NestedModel(Tornillo, null, NestedBehavior.SendIriOnly)
    })
    private _tornillo: Tornillo;

    @BasicProperty({
        name: 'Cantidad',
        type: PropertyType.Integer()
    })
    private _cantidad: number = 0;

    @BasicProperty({
        name: 'Importe opción',
        type: PropertyType.Float()
    })
    private _importe: number = 0;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get lineaPedido() {
        return this._lineaPedido;
    }

    set lineaPedido(value) {
        this._lineaPedido = value;
    }

    get tornillo(): Tornillo {
        return this._tornillo;
    }

    set tornillo(value: Tornillo) {
        this._tornillo = value;
    }

    get cantidad(): number {
        return this._cantidad;
    }

    set cantidad(value: number) {
        this._cantidad = value;
    }

    get importe(): number {
        return this._importe;
    }

    set importe(value: number) {
        this._importe = value;
    }
}
