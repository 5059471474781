<div class="container">
    <div class="uploader_input">
        <div>
            <input #file1 type="file" id="fileInput" multiple (change)="selectFile($event)" name="fileInput"
                   hidden="hidden"/>
        </div>
        <mat-toolbar *ngIf="progress > 0 && progress < 100" class="progress-bar">
            <mat-progress-bar color="primary" [value]="progress"></mat-progress-bar>
            <span class="progress">{{ progress }}% - {{ progressType }}</span>
        </mat-toolbar>
        <mat-progress-bar mode="indeterminate" color="primary"
                          *ngIf="seleccionando && progress === 100"></mat-progress-bar>
        <div class="archivos" (drop)="dropEvent($event)" (dragover)="onDragOver($event)">
            <mat-card>
                <div class="header">
                    <h3>Total {{ listaArchivos ? listaArchivos.length.toString() : '' }} archivos</h3>
                    <button mat-raised-button color="primary" (click)="file1.click()"
                            *ngIf="!disableButton">{{ buttonName }}
                    </button>
                </div>
                <mat-card-content *ngIf="!listaArchivos || listaArchivos.length === 0">
                    <div class="items_margin" *ngIf="modelType && modelType.name === 'ArchivoSTL'">Arrastra los ficheros
                        aquí
                    </div>
                    <div class="items_margin" *ngIf="modelType && modelType.name === 'ArchivoIntraoral'">Arrastra el
                        fichero aquí
                    </div>
                    <div class="items_margin" *ngIf="modelType && modelType.name === 'ArchivoSTL'"><i>Ten en cuenta que
                        sólo
                        podrás subir archivos de tipo STL / ZIP / 3OX o CONSTRUCTIONINFO</i></div>
                    <div class="items_margin" *ngIf="modelType && modelType.name === 'ArchivoIntraoral'"><i>Ten en
                        cuenta que sólo podrás subir archivos de tipo STL, PLY o OBJ</i></div>
                </mat-card-content>
                <mat-card-content *ngIf="listaArchivos && listaArchivos.length > 0">
                    <div #lineaArchivo class="linea_archivo items_margin"
                         *ngFor="let archivo of listaArchivos; let i = index" (click)="click(archivo)"
                         [class.seleccionado]="archivoSeleccionado && archivoSeleccionado.id === archivo.id"
                         [class.seleccionando]="archivo === archivoSiguiente">
                        <div>{{ nombreFichero(archivo) }}
                        </div>
                        <div>
                            <button mat-icon-button (click)="borrar(archivo, i)"
                                    *ngIf="!disableButton">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
                <!--                <mat-card-actions *ngIf="listaArchivos && listaArchivos.length > 0 && !disableButton">-->
                <!--                    <button mat-raised-button color="warn" (click)="borrarTodos()">Borrar todos-->
                <!--                        <mat-icon>delete_forever</mat-icon>-->
                <!--                    </button>-->
                <!--                </mat-card-actions>-->
            </mat-card>
        </div>
    </div>
    <div class="visor" *ngIf="mostrarVisor">
        <div class="objViewer" #objViewer></div>
    </div>
</div>
