import {Injectable} from '@angular/core';
import {BehaviorSubject, timer} from 'rxjs';
import {switchMap, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class HttpRequestService {
    private activeRequests = 0;
    private isLoadingSubject = new BehaviorSubject<boolean>(false);
    private loadingDelay = 1000;
    private hideTimeout: any;

    constructor() {
    }

    // Incrementar el contador cuando una solicitud empieza
    incrementRequests() {
        this.activeRequests++;
        this.updateLoadingState();
    }

    // Decrementar el contador cuando una solicitud termina
    decrementRequests() {
        this.activeRequests--;
        this.updateLoadingState();
    }

    // Determinar si hay solicitudes en ejecución
    get isLoading$() {
        return this.isLoadingSubject.asObservable();
    }

    // Actualizar el estado de carga
    private updateLoadingState() {
        if (this.activeRequests > 0) {
            // Si hay solicitudes activas, mostrar "Cargando..." inmediatamente
            this.isLoadingSubject.next(true);

            // Limpiar cualquier temporizador anterior de ocultación
            if (this.hideTimeout) {
                clearTimeout(this.hideTimeout);
            }
        } else {
            // Si no hay solicitudes activas, configurar un retraso para ocultar
            this.hideTimeout = setTimeout(() => {
                this.isLoadingSubject.next(false);
            }, this.loadingDelay);
        }
    }
}
