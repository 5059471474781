import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from "../api/api.service";
import {finalize, map, pluck} from "rxjs/operators";
import {HttpUrlEncodingCodecCorrected} from "../api/http-url-encoding-codec";
import {saveAs} from 'file-saver';
import {HttpClient} from "@angular/common/http";
import {InterfaceProviderService} from "../basic-entity-back/services/interface-provider.service";
import {Resource} from "../api/resource";
import {SessionService} from "../session/session.service";

/**
 * Este servicio coordina la búsqueda entre el header y el table component
 * que se esté mostrando.
 */
@Injectable({
    providedIn: 'root'
})
export class FacturasService {

    _finalizadoEmitter = new EventEmitter<boolean>();
    _authorization = '';

    constructor(private apiService: ApiService, private _http: HttpClient, private interfaceProvider: InterfaceProviderService, private sessionService: SessionService) {
        this._authorization = 'Bearer ' + this.sessionService.token.token;
    }

    imprimir(entity: Resource) {
        const titulo = this.interfaceProvider.interfaceForModel(entity.modelType).name;
        this.apiService.patch(`${entity.iri}/descargar`, {})
            .pipe(
                ApiService.sTakeBody(),
                pluck('downloadToken'),
                map((token: string) =>
                    ApiService.sCompleteUrl(`${entity.iri}/pdf?token=${new HttpUrlEncodingCodecCorrected().encodeValue(token)}`))
            )
            .subscribe(
                (url: any) => {
                    this._http.get(url, {responseType: "arraybuffer", headers: {Authorization: this._authorization}})
                        .subscribe((datos: any) => {
                            const filename = ` ${titulo} ${entity['numeroFactura']} - ${entity['cliente'].nombreCompleto}.pdf`;
                            const blob = new Blob([datos], {type: 'application/pdf'});
                            saveAs(blob, filename);
                            this._finalizadoEmitter.next(true);
                        }, error => this._finalizadoEmitter.next(true));

                }, error => this._finalizadoEmitter.next(true));
    }

    get finalizadoEmitter() {
        return this._finalizadoEmitter;
    }
}
