/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */

import {Caster} from "../casters/caster";
import {PropertyTypeInterface} from "../property-type/property-type-interface";
import {Resource} from "../../api/resource";
import {Type} from "@angular/core";
import {Filter} from "../../api/filter-interface";

/**
 * Indicates whether a property is read-only, write-only or read-write
 */
export enum ReadWrite {
    ReadWrite,
    ReadOnly,
    WriteOnly
}

/**
 * A filter group groups several filters so we don't have to specify them one by one
 */
export type FilterGroup = Filter[];
export type FilterDefinition = Filter | FilterGroup;

export interface ObjectPropertyMap {
    /** Key for the property in the map answers or requests */
    keyInApi?: string;
    /** Indicates that the property can be null */
    nullable?: boolean;
    /**
     * Optional caster to apply to the value of the model to get the value for the API and vice versa.
     * It is applied BEFORE the default caster of the BasicEntityInterface when parsing from the API
     */
    preCaster?: Caster<any, any>;
    /**
     * Optional caster to apply to the value of the model to get the value for the API and vice versa.
     * It is applied AFTER the default caster of the BasicEntityInterface when parsing from the API
     */
    posCaster?: Caster<any, any>;
    /**
     * A name to display the property (in tables, edition, etc)
     */
    name?: string;
    /**
     * Indicates if the property should be read from/written to the model.
     * Properties which are read-only will never be sent in insertions or updates.
     * Properties which are write-only will never be fetched from the API.
     * Default value is ReadWrite
     */
    readWrite?: ReadWrite;
    /**
     * Indicates if the property is read from the API or generated by the own model.
     * A value of true indicates it should not be read from the API.
     * If the property is not read from the API it will be still writable if it is not read-only.
     */
    dontReadFromApi?: boolean;
    /**
     * The type of attribute for this property, important to make sure it is displayed right in the BasicEntityTableComponent.
     * Choose from PropertyType constant.
     */
    type: PropertyTypeInterface;
    /** Indicates whether this property is an array or not (of the given type) */
    array?: boolean;
    order?: number;
}

/**
 * Interface to map a property from the API to the model for the BasicEntityInterface to use
 */
export interface PropertyMap extends ObjectPropertyMap {
    /** Indicates if the property is an ID, false by default */
    isId?: boolean;
    /** Whether the entity can be sorted by this column or not */
    sortable?: boolean;
    /**
     * Can the entity be filtered by this property? How is the search performed?
     * @deprecated Use filters instead
     * @see PropertyMap.filters
     */
    searchable?: SearchType;
    filters?: FilterDefinition | FilterDefinition[];
    /** Param to send to the API to search, filter or sort by this parameter, if it is not present the interface will try to elaborate it*/
    paramName?: string;
    /**
     * Display only on the creation of the element, not when updating it. Defaults to false.
     */
    onlyOnNew?: boolean;
    /**
     * Use this to indicate a property that must be evaluated to 'true' for this one to be relevant.
     * Notice the value of this property may not be available for edition when the indicated
     * property evaluates to false.
     * Have in account the value of the property is nullable if the referenced property evaluates to false.
     */
    dependsOn?: string;
    /**
     * Trabaja con dependsOn. Si es true, entonces niega la propiedad especificada en dependson
     */
    dependsOnContrary?: boolean;
    /** The tag indicates this property is related to others with the same tag */
    tag?: string;
    /** Fixed filter in filter list **/
    fixedFilter?: boolean
}

/**
 * Type of search which is performed over a property
 */
export enum SearchType {
    No,
    Exact,
    Partial,
    Start,
    End,
    WordStart,
    Boolean
}

export enum ManagerCachingStrategy {
    NoCache,
    PrecacheOnStart,
    Lazy
}

export interface BasicEntityInterfaceBaseDetails {
    /** Name (to display) for the objects managed by the interface. For example: "Renaissance paintings" */
    name: string;
    /** Is the entity paginated? */
    isPaginated: boolean;
    /** Which page sizes are allowed for this entity? */
    paginationSizes?: number[];
    /** What is the endpoint in the API for this entity? */
    endpoint: string;
    /** If set, indicates the id is autogenerated, by default it is true */
    autogeneratedId?: boolean;
    /** Which of the mapped properties should be used to show the object when nested or referenced by Uri? */
    modelNameProperty?: string;
    /** A pattern to use to obtain the name of a model managed by this interface. Properties can be used with {{property}}. */
    modelNamePattern?: string;
    /** If true, the interface can only be seen but not edited */
    readOnlyInterface?: boolean;
    /** If true, the interface allows to send a bunch of parameters iri-search[] with IRIs to get the items for them */
    acceptsBulkIriSearch?: boolean;
    /** Determines if the entities with this interface should be preloaded and cached by the manager. Please use this only if
     * you know that there will be very few entities with this interface. */
    managerCaching?: ManagerCachingStrategy;
}

/**
 * General details about the interface, this is the interface used on the annotations (before processed)
 */
export interface BEIDetailsDescription extends BasicEntityInterfaceBaseDetails {
    /** What is the entity type in the API (@type) for this entity? */
    type?: string;
}

/**
 * General details about the interface, this is the interface used internally (once processed)
 */
export interface BasicEntityInterfaceDetails extends BasicEntityInterfaceBaseDetails {
    /** What is the entity type in the API (@type) for this entity? */
    type: string;
}

/**
 * Complete description of an interface with all the necessary data to construct it
 */
export interface BasicEntityInterfaceDescription<T extends Resource> extends BasicEntityInterfaceDetails {
    /** Model of the interface */
    model: Type<T>;
    /** Mapping of the model to the API */
    mappingModelToApi: { [s: string]: PropertyMap };
}
