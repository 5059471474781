import {DomSanitizer} from '@angular/platform-browser';
import {Component, OnInit} from '@angular/core';
import {MENU_ENDPOINT, MenuItem} from './shell/menu/menu.component';
import {ApiService} from './api/api.service';
import {TranslationInputService} from './basic-entity-back/services/translation-input.service';
import {Idioma} from './model/localizacion/idioma.model';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatDialogRef} from '@angular/material/dialog';
import {MatDrawer} from '@angular/material/sidenav';
import {SessionService} from './session/session.service';
import {ErrorDisplayService} from './basic-entity-front/services/error-display.service';
import {SimpleDialogService} from './basic-entity-front/dialog-shell/simple-dialog.service';
import {ChildrenOutletContexts, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DeviceDetectorService} from './services/device-detector.service';
import {flip} from "./basic-entity-front/animations/animations";
import {Cliente} from "./model/personas/cliente.model";
import {Usuario} from "./model/personas/usuario.model";
import {HttpRequestService} from "./shell/loading/http-request.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [flip]
})
export class AppComponent implements OnInit {
    public menuItems: MenuItem[] = [];
    // public isHandset: boolean = window.matchMedia('only screen and (max-width: 1024px)').matches;
    public isHandset$: Observable<boolean> = this.deviceDetector.isHandset();
    private _networkDialogRef: MatDialogRef<any> = null;
    cargando = false;
    mostrarCargando = false;

    constructor(private _api: ApiService,
                translation: TranslationInputService,
                private sessionService: SessionService,
                dialogService: SimpleDialogService,
                private router: Router,
                public errorService: ErrorDisplayService,
                public breakpointObserver: BreakpointObserver,
                private matIconRegistry: MatIconRegistry,
                private deviceDetector: DeviceDetectorService,
                private contexts: ChildrenOutletContexts,
                private domSanitizer: DomSanitizer,
                private httpRequestService: HttpRequestService) {
        this._updateMenu();
        this.sessionService.sessionChanges
            .subscribe(() => {
                this._updateMenu();
                if (!sessionService.isLogged()) {
                    router.navigate(['.']);
                }
            }, err => errorService.displayError(err));
        translation.initialise(Idioma);
        translation.preload();

        window.addEventListener('offline', () => {
            this._networkDialogRef = dialogService.simpleDialog(
                'Sin conexión',
                'Esta web no puede operar sin conexión a internet.',
                [], true, false, null);
        });
        window.addEventListener('online', () => {
            if (this._networkDialogRef) {
                this._networkDialogRef.close();
                this._networkDialogRef = null;
            }
        });
    }


    ngOnInit(): void {
        // Suscribirse al observable de isLoading para detectar cambios en el estado de las solicitudes
        this.httpRequestService.isLoading$.subscribe(isLoading => {
            this.cargando = isLoading;
        });

        // Solo muestro el cargando en estas páginas
        this.router.events.subscribe((event) => {
            this.mostrarCargando = this.sessionService.isLogged() && this.router.url.indexOf('inicio') === -1;
        });
    }

    public menuItemClicked(drawer: MatDrawer) {
        this.sessionService.profileAsObservable.subscribe((cliente: Cliente) => {
            if (cliente && (cliente.usuario as Usuario).passwordValidUntil !== null) {
                this.router.navigate(['/password-reset']).then(v => this.errorService.displayRaw("Debe cambiar la contraseña antes de realizar cualquier otra acción"))
            } else {
                if (cliente && cliente.empresa && (!cliente.denominacionSocial || !cliente.cifNif)) {
                    this.router.navigate(['/datos']).then(v => this.errorService.displayRaw("Siendo persona jurídica necesitamos denominación social y CIF"))
                }
                if (cliente && !cliente.empresa && (!cliente.nombre || !cliente.apellido1 || !cliente.cifNif)) {
                    this.router.navigate(['/datos']).then(v => this.errorService.displayRaw("Debe completar nombre, apellido y NIF de la persona física"))
                }
            }
        })
        if (!(this.breakpointObserver.isMatched(Breakpoints.XLarge) || this.breakpointObserver.isMatched(Breakpoints.Large))) {
            drawer.close();
        }
    }

    private _updateMenu() {
        if (this.sessionService.isLogged()) {
            this._api.getCollection<MenuItem>(MENU_ENDPOINT)
                .pipe(ApiService.sTakeBody())
                .subscribe(menuItems => {
                    this.menuItems = menuItems.member;
                }, err => this.errorService.displayError(err));
        }
    }

    public isLogged(): boolean {
        return this.sessionService.isLogged();
    }

    getRouteAnimationData() {
        return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
    }

}
