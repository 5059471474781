import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Opcion} from "./opcion.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";

@BasicEntity({
    name: 'Trabajo',
    endpoint: 'trabajos',
    type: "Trabajo",
    isPaginated: false,
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'textoAMostrar'
})
export class Trabajo extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer(),
        sortable: true
    })
    private _id: number;

    @BasicProperty({
        name: 'Descripción',
        type: PropertyType.String()
    })
    private _descripcion: string;

    @BasicProperty({
        name: 'Descripción',
        type: PropertyType.String()
    })
    private _descripcionAMostrar: string = '';

    @BasicProperty({
        name: 'Métodos',
        type: PropertyType.Uri(MetodoFabricacion),
        array: true
    })
    private _metodos: MetodoFabricacion[];

    @BasicProperty({
        name: 'Unitaria',
        type: PropertyType.Boolean()
    })
    private _unitaria: boolean;

    @BasicProperty({
        name: 'Opciones',
        type: PropertyType.NestedModel(Opcion, null, NestedBehavior.SendIriOnly),
        array: true
    })
    private _opciones: Opcion[]

    @BasicProperty({
        name: 'Tiene subtrabajo',
        type: PropertyType.Boolean(),
        dontReadFromApi: true
    })
    private _tieneSubtrabajo = false;

    @BasicProperty({
        name: 'Carpeta de destino (Validaciones)',
        type: PropertyType.String(),
        nullable: true
    })
    private _carpetaDestino;

    get textoAMostrar() {
        if (this._descripcionAMostrar) return this._descripcionAMostrar;
        return this._descripcion;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get descripcion(): string {
        return this._descripcion;
    }

    set descripcion(value: string) {
        this._descripcion = value;
    }

    get metodos(): MetodoFabricacion[] {
        return this._metodos;
    }

    set metodos(value: MetodoFabricacion[]) {
        this._metodos = value;
    }

    get unitaria(): boolean {
        return this._unitaria;
    }

    set unitaria(value: boolean) {
        this._unitaria = value;
    }

    get opciones(): Opcion[] {
        return this._opciones;
    }

    set opciones(value: Opcion[]) {
        this._opciones = value;
    }

    get tieneSubtrabajo(): boolean {
        return this._tieneSubtrabajo;
    }

    set tieneSubtrabajo(value: boolean) {
        this._tieneSubtrabajo = value;
    }

    get carpetaDestino() {
        return this._carpetaDestino;
    }

    set carpetaDestino(value) {
        this._carpetaDestino = value;
    }

    get descripcionAMostrar(): string {
        return this._descripcionAMostrar;
    }

    set descripcionAMostrar(value: string) {
        this._descripcionAMostrar = value;
    }
}
