import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Archivo} from "./archivo.model";

@BasicEntity({
    name: 'Archivo',
    endpoint: 'archivo_s_t_ls',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "ArchivoSTL"
})
export class ArchivoSTL extends Archivo {
    public static readonly PEDIDO_MAP: PropertyMap = {
        name: 'Pedido',
        type: PropertyType.FutureUri('linea-pedido.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(ArchivoSTL.PEDIDO_MAP)
    private _lineaPedido;

    get lineaPedido() {
        return this._lineaPedido;
    }

    set lineaPedido(value) {
        this._lineaPedido = value;
    }
}
