import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {BaseEntity} from "../base-entity.model";

@BasicEntity({
    name: 'Comentario revisión de solicitudes',
    endpoint: 'comentario_revisions',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "ComentarioRevision"
})
export class ComentarioRevision extends BaseEntity {

    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    public static readonly SOLICITUD_MAP: PropertyMap = {
        name: 'Solicitud',
        type: PropertyType.FutureUri('solicitud.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(ComentarioRevision.SOLICITUD_MAP)
    private _solicitud;

    @BasicProperty({
        name: 'Comentario',
        type: PropertyType.Text()
    })
    private _comentario;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get solicitud() {
        return this._solicitud;
    }

    set solicitud(value) {
        this._solicitud = value;
    }

    get comentario() {
        return this._comentario;
    }

    set comentario(value) {
        this._comentario = value;
    }
}
