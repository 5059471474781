import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Archivo} from "./archivo.model";

@BasicEntity({
    name: 'Archivo',
    endpoint: 'archivo_s_t_l_linea_solicituds',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "ArchivoSTLLineaSolicitud",
    modelNameProperty: 'nombreFichero'
})
export class ArchivoSTLLineaSolicitud extends Archivo {
    public static readonly LINEA_SOLICITUD_MAP: PropertyMap = {
        name: 'Lnea Solicitud',
        type: PropertyType.FutureUri('linea-solicitud.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(ArchivoSTLLineaSolicitud.LINEA_SOLICITUD_MAP)
    private _lineaSolicitud;


    get lineaSolicitud() {
        return this._lineaSolicitud;
    }

    set lineaSolicitud(value) {
        this._lineaSolicitud = value;
    }
}
