import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditionDialogComponent} from "./edition-dialog/edition-dialog.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSnackBarContainer, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SimpleDialogComponent} from "./dialog-shell/simple-dialog.service";
import {MatMomentDateModule} from "./date-adapter";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BasicEntityTableComponent} from "./basic-entity-table/basic-entity-table.component";
import {BasicEntityFilteringComponent} from "./basic-entity-filtering/basic-entity-filtering.component";
import {BasicEntityInputComponent} from "./basic-entity-input/basic-entity-input.component";
import {TimeInputComponent} from "./basic-entity-input/time-input/time-input.component";
import {DateTimeInputComponent} from "./basic-entity-input/date-time-input/date-time-input.component";
import {UriInputComponent} from "./basic-entity-input/uri-input/uri-input.component";
import {NestedInputComponent} from "./basic-entity-input/nested-input/nested-input.component";
import {KeyValueInputComponent} from "./basic-entity-input/key-value-input/key-value-input.component";
import {DictionaryInputComponent} from "./basic-entity-input/dictionary-input/dictionary-input.component";
import {BasicEntityCellComponent} from "./basic-entity-table/basic-entity-cell/basic-entity-cell.component";
import {DialogHeaderComponent} from "./dialog-header/dialog-header.component";
import {DialogActionsDirective, DialogShellComponent} from "./dialog-shell/dialog-shell.component";
import {MultiModelListComponent} from "./multi-model-list/multi-model-list.component";
import {EditionTabComponent} from "./edition-dialog/edition-tab/edition-tab.component";
import {NullablePropPipe} from "./pipes/nullable-prop.pipe";
import {BasicEntityBackModule} from "../basic-entity-back/basic-entity-back.module";
import {RouterModule} from "@angular/router";
import {ApiModule} from "../api/api.module";
import {EditionRowComponent} from './edition-dialog/edition-row/edition-row.component';
import {CachedInputComponent} from './basic-entity-input/cached-input/cached-input.component';
import {FilterListComponent} from './basic-entity-filtering/filter-list/filter-list.component';
import {DefaultFilteringComponent} from './basic-entity-filtering/default-filtering/default-filtering.component';
import {RangeFilteringComponent} from './basic-entity-filtering/range-filtering/range-filtering.component';
import {ExistsFilteringComponent} from './basic-entity-filtering/exists-filtering/exists-filtering.component';
import {DateFilteringComponent} from './basic-entity-filtering/date-filtering/date-filtering.component';
import {PhotoPreviewComponent} from './basic-entity-table/basic-entity-cell/photo-preview/photo-preview.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatTableExporterModule} from 'mat-table-exporter';
import {NgxMatTimepickerModule} from "ngx-mat-timepicker";
import {FileUploadComponent} from "./basic-entity-input/file-upload/file-upload.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {BasicEntityFiltering2Component} from './basic-entity-filtering/basic-entity-filtering2.component';
import {FileUploadComponent2} from "./basic-entity-input/file-upload2/file-upload.component2";

@NgModule({
    declarations: [
        NullablePropPipe,
        BasicEntityTableComponent,
        BasicEntityFilteringComponent,
        EditionDialogComponent,
        BasicEntityInputComponent,
        TimeInputComponent,
        DateTimeInputComponent,
        UriInputComponent,
        BasicEntityCellComponent,
        NestedInputComponent,
        DictionaryInputComponent,
        KeyValueInputComponent,
        DialogHeaderComponent,
        DialogShellComponent,
        DialogActionsDirective,
        MultiModelListComponent,
        EditionTabComponent,
        SimpleDialogComponent,
        EditionRowComponent,
        CachedInputComponent,
        FilterListComponent,
        DefaultFilteringComponent,
        RangeFilteringComponent,
        ExistsFilteringComponent,
        DateFilteringComponent,
        PhotoPreviewComponent,
        FileUploadComponent,
        FileUploadComponent2,
        BasicEntityFiltering2Component
    ],
    imports: [
        ApiModule,
        BasicEntityBackModule,
        CommonModule,
        RouterModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatDialogModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSortModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatIconModule,
        MatListModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        MatTableExporterModule,
        NgxMatTimepickerModule,
        MatToolbarModule
    ],
    exports: [
        BasicEntityTableComponent,
        BasicEntityFilteringComponent,
        BasicEntityInputComponent,
        EditionDialogComponent,
        TimeInputComponent,
        DateTimeInputComponent,
        UriInputComponent,
        CachedInputComponent,
        NestedInputComponent,
        KeyValueInputComponent,
        DictionaryInputComponent,
        BasicEntityCellComponent,
        DialogHeaderComponent,
        DialogShellComponent,
        DialogActionsDirective,
        MultiModelListComponent,
        EditionTabComponent,
        EditionRowComponent,
        FileUploadComponent,
        FileUploadComponent2
    ]
})
export class BasicEntityFrontModule {
}
