import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Material} from "../materiales/material.model";
import {ColorMaterial} from "../materiales/color.model";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Uri} from "../../api/uri";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {MetodoFabricacion} from "../materiales/metodo-fabricacion.model";
import {DireccionPersona} from "../personas/direccion-persona.model";
import {Proveedor} from "../personas/proveedor.model";
import {TipoMaterial} from "../materiales/tipo-material.model";
import {Trabajo} from "../trabajos/trabajo.model";
import {SubTrabajo} from "../trabajos/subtrabajo.model";
import {Tarifa} from "../trabajos/tarifa.model";
import {LineaPedidoOpcion} from "./linea-pedido-opcion.model";
import {ArchivoSTL} from "../archivos/archivo-stl.model";
import {LineaPedidoTornillo} from "./linea-pedido-tornillo.model";
import {ExactSearchFilter, PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {BaseEntity} from "../base-entity.model";
import {TipoConexion} from "../trabajos/tipo-conexion.model";
import {DescuentoCliente} from "../trabajos/descuento-cliente.model";

@BasicEntity({
    name: 'Lineas Pedido',
    endpoint: 'linea_pedidos',
    modelNameProperty: 'resumen',
    isPaginated: true
})
export class LineaPedido extends BaseEntity {
    static readonly ESTADOS_LINEA_PEDIDO = {
        // PENDIENTE: 'Pendiente',
        // CONFIRMADO: "Confirmado",
        // VALIDADO: "Validado",
        // EN_PRODUCCION: "En producción",
        // ENVIADO: "Enviado",
        // EN_REPARTO: 'En reparto',
        // FINALIZADO: 'Finalizado',
        // CANCELADO: 'Cancelado'
        EN_CURSO: "En curso",
        REALIZADO: "Realizado",
        RECHAZADO: "Rechazado",
        PENDIENTE_VALIDAR: "Pendiente de validación",
        VALIDADO: "Validado",
        EN_PRODUCCION: "En producción",
        LISTO_PARA_ENVIO: "Listo para envío",
        EN_TRANSITO: "En tránsito",
        ENTREGADO: "Entregado",
        CANCELADO: "Cancelado"
    };

    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    public static readonly MAP_PEDIDO: PropertyMap = {
        name: 'Pedido',
        type: PropertyType.FutureUri('pedido.model.ts')
    }
    @BasicProperty(LineaPedido.MAP_PEDIDO)
    private _pedido;

    @BasicProperty({
        name: 'Código Paciente',
        type: PropertyType.String(),
        nullable: false
    })
    private _codigoPaciente: string;

    @BasicProperty({
        name: 'Código Clínico (opcional)',
        type: PropertyType.String(),
        nullable: true
    })
    private _codigoClinico;

    @BasicProperty({
        name: 'Archivo',
        type: PropertyType.NestedModel(ArchivoSTL, 'lineaPedido', NestedBehavior.SendIriOnly)
    })
    private _archivo: ArchivoSTL;

    @BasicProperty({
        name: 'Método',
        type: PropertyType.NestedModel(MetodoFabricacion, undefined, NestedBehavior.SendIriOnly),
        filters: [ExactSearchFilter]
    })
    private _metodoFabricacion: MetodoFabricacion;

    @BasicProperty({
        name: 'Material',
        type: PropertyType.NestedModel(Material, undefined, NestedBehavior.SendIriOnly),
        filters: [ExactSearchFilter]
    })
    private _material: Material;

    @BasicProperty({
        name: 'Acabado',
        type: PropertyType.NestedModel(TipoMaterial, undefined, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _acabado?: Material;

    @BasicProperty({
        name: 'Color',
        type: PropertyType.NestedModel(ColorMaterial, undefined, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _color: ColorMaterial;

    @BasicProperty({
        name: 'Dirección de entrega',
        type: PropertyType.Uri(DireccionPersona)
    })
    private _direccionEntrega: Uri;

    @BasicProperty({
        name: 'Transporte',
        type: PropertyType.Uri(Proveedor)
    })
    private _transporte: Uri;

    @BasicProperty({
        name: 'Tarifa aplicada',
        type: PropertyType.NestedModel(Tarifa, null, NestedBehavior.SendIriOnly)
    })
    private _tarifaAplicada;

    @BasicProperty({
        name: 'Trabajo',
        type: PropertyType.NestedModel(Trabajo, null, NestedBehavior.SendIriOnly),
        filters: [ExactSearchFilter]
    })
    private _trabajo;

    @BasicProperty({
        name: 'Trabajos',
        type: PropertyType.Object(),
        readWrite: ReadWrite.ReadOnly,
        array: true
    })
    private _trabajos = [];

    @BasicProperty({
        name: 'Subtrabajo',
        type: PropertyType.NestedModel(SubTrabajo, null, NestedBehavior.SendIriOnly),
        filters: [ExactSearchFilter]
    })
    private _subTrabajo;

    @BasicProperty({
        name: 'Subtrabajo obligado',
        type: PropertyType.NestedModel(SubTrabajo, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _subTrabajoObligado;

    @BasicProperty({
        name: 'Opciones',
        type: PropertyType.NestedModel(LineaPedidoOpcion, null, NestedBehavior.CreateOnly),
        array: true,
        nullable: true
    })
    private _opciones;

    @BasicProperty({
        name: 'Tipo de conexión',
        type: PropertyType.NestedModel(TipoConexion, undefined, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _tipoConexion: TipoConexion | null;

    @BasicProperty({
        name: 'Datos extra del tipo de conexión',
        type: PropertyType.String(),
        nullable: true
    })
    private _datosExtraTipoConexion: string | null = '';

    @BasicProperty({
        name: 'Cantidad',
        type: PropertyType.Integer()
    })
    private _cantidad: number = 0;

    @BasicProperty({
        name: 'Cantidad Subtrabajo obligado',
        type: PropertyType.Integer()
    })
    private _cantidadSubTrabajoObligado: number = 0;

    @BasicProperty({
        name: 'Importe trabajos',
        type: PropertyType.Float()
    })
    private _importeTrabajos: number = 0;

    @BasicProperty({
        name: 'Importe trabajos (Sin descuento)',
        type: PropertyType.Float()
    })
    private _importeTrabajosSinDescuento: number = 0;

    @BasicProperty({
        name: 'Importe linea',
        type: PropertyType.Float()
    })
    private _importeTotal: number = 0;

    @BasicProperty({
        name: 'Importe linea (Sin descuento)',
        type: PropertyType.Float()
    })
    private _importeTotalSinDescuento: number = 0;

    @BasicProperty({
        name: 'Observaciones',
        nullable: true,
        type: PropertyType.Text()
    })
    private _observaciones: string;

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(LineaPedido.ESTADOS_LINEA_PEDIDO)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _estado;

    @BasicProperty({
        name: 'Tornilleria',
        type: PropertyType.NestedModel(LineaPedidoTornillo, 'lineaPedido', NestedBehavior.CreateOnly),
        array: true,
        nullable: true
    })
    private _tornilleria = [];

    @BasicProperty({
        name: 'Descuentos aplicados',
        type: PropertyType.NestedModel(DescuentoCliente, null, NestedBehavior.SendIriOnly),
        array: true,
        readWrite: ReadWrite.ReadOnly
    })
    private _descuentosAplicados = [];

    @BasicProperty({
        name: 'Concepto',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _resumen = '';

    @BasicProperty({
        name: 'Cliente',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        filters: [PartialSearchFilter]
    })
    private _cliente = '';

    @BasicProperty({
        name: 'Trabajos',
        type: PropertyType.String(),
        dontReadFromApi: true,
        readWrite: ReadWrite.ReadOnly
    })
    get trabajosString() {
        return this._trabajos.map(t => t[0] + '(' + t[1] + ')').join(',')
    }

    @BasicProperty({
        name: 'Número de pieza',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly
    })
    private _numeroPieza;

    @BasicProperty({
        name: 'Linea Repetida',
        type: PropertyType.NestedModel(LineaPedido, null, NestedBehavior.SendIriOnly),
        readWrite: ReadWrite.ReadOnly
    })
    private _lineaRepetida;

    @BasicProperty({
        name: 'Motivo de rechazo',
        type: PropertyType.String(),
        nullable: true,
        readWrite: ReadWrite.ReadOnly
    })
    private _motivoRechazo = null;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get pedido(): Uri {
        return this._pedido;
    }

    set pedido(value: Uri) {
        this._pedido = value;
    }

    get codigoPaciente(): string {
        return this._codigoPaciente;
    }

    set codigoPaciente(value: string) {
        this._codigoPaciente = value;
    }

    get codigoClinico() {
        return this._codigoClinico;
    }

    set codigoClinico(value) {
        this._codigoClinico = value;
    }

    get archivo(): ArchivoSTL {
        return this._archivo;
    }

    set archivo(value: ArchivoSTL) {
        this._archivo = value;
    }

    get metodoFabricacion(): MetodoFabricacion {
        return this._metodoFabricacion;
    }

    set metodoFabricacion(value: MetodoFabricacion) {
        this._metodoFabricacion = value;
    }

    get material(): Material {
        return this._material;
    }

    set material(value: Material) {
        this._material = value;
    }

    get color(): ColorMaterial {
        return this._color;
    }

    set color(value: ColorMaterial) {
        this._color = value;
    }

    get direccionEntrega() {
        return this._direccionEntrega;
    }

    set direccionEntrega(value) {
        this._direccionEntrega = value;
    }

    get transporte(): Uri {
        return this._transporte;
    }

    set transporte(value: Uri) {
        this._transporte = value;
    }

    get acabado(): Material {
        return this._acabado;
    }

    set acabado(value: Material) {
        this._acabado = value;
    }

    get tarifaAplicada() {
        return this._tarifaAplicada;
    }

    set tarifaAplicada(value) {
        this._tarifaAplicada = value;
    }

    get trabajo() {
        return this._trabajo;
    }

    set trabajo(value) {
        this._trabajo = value;
    }

    get subTrabajo() {
        return this._subTrabajo;
    }

    set subTrabajo(value) {
        this._subTrabajo = value;
    }

    get subTrabajoObligado() {
        return this._subTrabajoObligado;
    }

    set subTrabajoObligado(value) {
        this._subTrabajoObligado = value;
    }

    get opciones() {
        return this._opciones;
    }

    set opciones(value) {
        this._opciones = value;
    }

    get cantidad(): number {
        return this._cantidad;
    }

    get cantidadSubTrabajoObligado(): number {
        return this._cantidadSubTrabajoObligado;
    }

    set cantidadSubTrabajoObligado(value: number) {
        this._cantidadSubTrabajoObligado = value;
    }

    set cantidad(value: number) {
        this._cantidad = value;
    }

    get importeTrabajos(): number {
        return this._importeTrabajos;
    }

    set importeTrabajos(value: number) {
        this._importeTrabajos = value;
    }

    get importeTotal(): number {
        return this._importeTotal;
    }

    set importeTotal(value: number) {
        this._importeTotal = value;
    }

    get observaciones(): string {
        return this._observaciones;
    }

    set observaciones(value: string) {
        this._observaciones = value;
    }

    get estado(): string {
        return this._estado;
    }

    set estado(value: string) {
        this._estado = value;
    }

    get tornilleria() {
        return this._tornilleria;
    }

    set tornilleria(value) {
        this._tornilleria = value;
    }

    get trabajos(): any[] {
        return this._trabajos;
    }

    set trabajos(value: any[]) {
        this._trabajos = value;
    }

    get descuentosAplicados(): any[] {
        return this._descuentosAplicados;
    }

    set descuentosAplicados(value: any[]) {
        this._descuentosAplicados = value;
    }

    get importeTrabajosSinDescuento(): number {
        return this._importeTrabajosSinDescuento;
    }

    set importeTrabajosSinDescuento(value: number) {
        this._importeTrabajosSinDescuento = value;
    }

    get importeTotalSinDescuento(): number {
        return this._importeTotalSinDescuento;
    }

    set importeTotalSinDescuento(value: number) {
        this._importeTotalSinDescuento = value;
    }

    get resumen(): string {
        return this._resumen;
    }

    set resumen(value: string) {
        this._resumen = value;
    }

    get lineaRepetida() {
        return this._lineaRepetida;
    }

    set lineaRepetida(value) {
        this._lineaRepetida = value;
    }

    get cliente(): string {
        return this._cliente;
    }

    set cliente(value: string) {
        this._cliente = value;
    }

    get numeroPieza() {
        return this._numeroPieza;
    }

    set numeroPieza(value) {
        this._numeroPieza = value;
    }

    get tipoConexion(): TipoConexion | null {
        return this._tipoConexion;
    }

    set tipoConexion(value: TipoConexion | null) {
        this._tipoConexion = value;
    }

    get datosExtraTipoConexion(): string | null {
        return this._datosExtraTipoConexion;
    }

    set datosExtraTipoConexion(value: string | null) {
        this._datosExtraTipoConexion = value;
    }

    get motivoRechazo() {
        return this._motivoRechazo;
    }

    set motivoRechazo(value) {
        this._motivoRechazo = value;
    }
}

ArchivoSTL.PEDIDO_MAP.type = PropertyType.Uri(LineaPedido)
LineaPedidoOpcion.MAP_LINEA_PEDIDO.type = PropertyType.Uri(LineaPedido)
LineaPedidoTornillo.MAP_LINEA_PEDIDO.type = PropertyType.Uri(LineaPedido)
