import {Component, OnDestroy, OnInit} from '@angular/core';
import {Widget} from "../widget";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {Pedido} from "../../../model/pedidos/pedido.model";
import {map} from "rxjs/operators";
import {FilterAndData, FilterList} from "../../../api/filter-list";
import {ExactSearchFilter} from "../../../basic-entity-back/filters/search-filter";

@Component({
    selector: 'app-pedidos-rechazados-widget',
    templateUrl: './pedidos-rechazados-widget.component.html',
    styleUrls: ['./pedidos-rechazados-widget.component.scss']
})
export class PedidosRechazadosWidgetComponent implements Widget, OnInit, OnDestroy {
    public static minCols = 1;
    public static minRows = 2;
    public static maxRows = 2;
    public static maxCols = 1;
    public static widgetName = 'Pedidos rechazados';
    public static widgetKey = 'pedidos-rechazados';
    numeroPedidosRechazados: number = 0;
    private _interval: any;
    private _intervalSeconds = 10;

    constructor(private interfaceProvider: InterfaceProviderService) {
        this.execute();
    }

    ngOnInit(): void {
        this._interval = setInterval(
            () => this.execute(),
            this._intervalSeconds * 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this._interval);
    }

    private execute() {
        this.numeroPedidosRechazados = 0;
        const estadoProp = this.interfaceProvider.interfaceForModel(Pedido).mappingModelToApi['estado'];
        const filters: FilterList = [new FilterAndData(ExactSearchFilter, [Pedido.ESTADOS_PEDIDO.RECHAZADO], estadoProp)];
        this.interfaceProvider.managerForModel(Pedido).loader.findAndFollow(filters).pipe(map(r => {
            return r.member;
        })).subscribe(ps => {
            this.numeroPedidosRechazados += ps.length;
        })
    }
}


