import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {ManagerCachingStrategy, PropertyMap} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Resource} from "../../api/resource";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {TipoMaterial} from "./tipo-material.model";
import {ColorMaterial} from "./color.model";

@BasicEntity({
    name: 'MaterialTipoMaterialRel',
    endpoint: 'material_tipo_material_rels',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    modelNameProperty: 'descripcion',
    type: 'MaterialTipoMaterialRel',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class MaterialTipoMaterialRel extends Resource {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    public static readonly MAP_MATERIAL: PropertyMap = {
        name: 'Material',
        type: PropertyType.FutureUri('material.model.ts')
    }
    @BasicProperty(MaterialTipoMaterialRel.MAP_MATERIAL)
    private _material;

    @BasicProperty({
        name: 'Tipos',
        type: PropertyType.NestedModel(TipoMaterial, null, NestedBehavior.SendIriOnly)
    })
    private _tipoMaterial;

    @BasicProperty({
        name: 'Colores Propios',
        type: PropertyType.NestedModel(ColorMaterial, null, NestedBehavior.SendIriOnly),
        array: true,
        nullable: true
    })
    private _coloresPropios;

    get descripcion() {
        return this.tipoMaterial.descripcion
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get material() {
        return this._material;
    }

    set material(value) {
        this._material = value;
    }

    get tipoMaterial() {
        return this._tipoMaterial;
    }

    set tipoMaterial(value) {
        this._tipoMaterial = value;
    }

    get coloresPropios() {
        return this._coloresPropios;
    }

    set coloresPropios(value) {
        this._coloresPropios = value;
    }
}
