import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {TarifaOpcion} from "../trabajos/tarifa-opcion.model";
import {Opcion} from "../trabajos/opcion.model";

@BasicEntity({
    name: 'Opción Linea Pedido',
    endpoint: 'linea_pedido_opcions',
    isPaginated: true
})
export class LineaPedidoOpcion extends Resource {

    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _id: number;

    public static readonly MAP_LINEA_PEDIDO: PropertyMap = {
        name: 'Linea Pedido',
        type: PropertyType.FutureUri('linea-pedido.model.ts')
    }
    @BasicProperty(LineaPedidoOpcion.MAP_LINEA_PEDIDO)
    private _lineaPedido;

    // public static readonly MAP_OPCION: PropertyMap = {
    //     name: 'Opcion',
    //     type: PropertyType.FutureUri('opcion.model.ts')
    // }
    // @BasicProperty(LineaPedidoOpcion.MAP_OPCION)
    // private _opcion;
    @BasicProperty({
        name: 'Opción',
        type: PropertyType.NestedModel(Opcion, null, NestedBehavior.SendIriOnly)
    })
    private _opcion: Opcion;

    @BasicProperty({
        name: 'Tarifa aplicada',
        type: PropertyType.NestedModel(TarifaOpcion, null, NestedBehavior.SendIriOnly)
    })
    private _tarifaAplicada;

    @BasicProperty({
        name: 'Cantidad',
        type: PropertyType.Integer()
    })
    private _cantidad: number = 0;

    @BasicProperty({
        name: 'Importe opción',
        type: PropertyType.Float()
    })
    private _importe: number = 0;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get lineaPedido() {
        return this._lineaPedido;
    }

    set lineaPedido(value) {
        this._lineaPedido = value;
    }

    get opcion() {
        return this._opcion;
    }

    set opcion(value) {
        this._opcion = value;
    }

    get tarifaAplicada() {
        return this._tarifaAplicada;
    }

    set tarifaAplicada(value) {
        this._tarifaAplicada = value;
    }

    get cantidad(): number {
        return this._cantidad;
    }

    set cantidad(value: number) {
        this._cantidad = value;
    }

    get importe(): number {
        return this._importe;
    }

    set importe(value: number) {
        this._importe = value;
    }
}
