<mat-toolbar [class.session-expiring]="sessionMinutesLeft < 5" color="primary"
             *ngIf="sessionService.isLogged() && (sessionService.hasRole('ROLE_USUARIO'))">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
            *ngIf="menuAvailable && (isHandset$ | async)">
        <mat-icon aria-label="Side nav toggle icon" color="primary">menu</mat-icon>
    </button>
    <!--    <a [routerLink]="['/inicio']"><img src="assets/Logo_Blanco.svg" alt="">-->
    <!--        <span style="color: white">CNC Dental</span></a>-->
    <span class="title">{{ title }}</span>
    <!-- <span class="clock" *ngIf="displayClock && mostrarToolBar">{{clock}}</span>
     <span class="fill-space"></span>
     <form class="search-box" autocomplete="off" [formGroup]="searchGroup"
           *ngIf="!searchService.hideSearch && mostrarToolBar">
         <mat-form-field appearance="outline" floatLabel="never" color="primary">
             <mat-label>Búsqueda...</mat-label>
             <input matInput type="text" formControlName="search">
             <mat-icon matSuffix>search</mat-icon>
         </mat-form-field>
     </form>
     <a
         aria-label="Reparador"
         mat-icon-button
         matTooltip="Reparador"
         routerLink="reparador"
         *ngIf="sessionService.isLogged() && sessionService.hasRole('ROLE_EMPLEADO') && mostrarToolBar">
         <mat-icon aria-label="Side nav reparador icon">build</mat-icon>
     </a>
     <a
         aria-label="Preparador"
         mat-icon-button
         matTooltip="Preparador"
         routerLink="preparador"
         *ngIf="sessionService.isLogged() && sessionService.hasRole('ROLE_EMPLEADO') && mostrarToolBar">
         <mat-icon aria-label="Side nav preparador icon">label_important</mat-icon>
     </a>
     <a
         aria-label="Kiosko"
         mat-icon-button
         matTooltip="Kiosko"
         routerLink="kiosko"
         *ngIf="sessionService.isLogged() && sessionService.hasRole('ROLE_EMPLEADO') && mostrarToolBar">
         <mat-icon aria-label="Side nav kiosko icon">view_list</mat-icon>
     </a>
     <a
         aria-label="Calendar"
         mat-icon-button
         matTooltip="Calendar"
         routerLink="calendar"
         *ngIf="sessionService.isLogged() && sessionService.hasRole('ROLE_EMPLEADO') && mostrarToolBar">
         <mat-icon aria-label="Side nav calendar icon">calendar_today</mat-icon>
     </a>
          <a
             aria-label="Estimator"
             mat-icon-button
             matTooltip="Find out the price of your bookings, including your discount"
             routerLink="tarificador"
             *ngIf="sessionService.isLogged()">

             <mat-icon aria-label="Side nav estimator icon">bookmark</mat-icon>
         </a>
     <a
         aria-label="Mis presupuestos"
         mat-icon-button
         matTooltip="Gestiona todos tus presupuestos a clientes finales"
         routerLink="presupuestos"
         *ngIf="sessionService.isLogged() && mostrarToolBar">
         <mat-icon aria-label="Side nav quotes icon">event_note</mat-icon>
     </a>
     <a
         aria-label="Mis reservas"
         mat-icon-button
         matTooltip="Todas tus reservas a un click"
         routerLink="reservas"
         [queryParams]="{'$f-b-0-confirmada': true, 'today-filter': 1}"
         *ngIf="sessionService.isLogged() && !sessionService.hasRole('ROLE_EMPLEADO') && mostrarToolBar">
         <mat-icon aria-label="Side nav quotes icon">event_available</mat-icon>
     </a>

     <a
         aria-label="Perfil"
         mat-button
         matTooltip="Perfil"
         routerLink="profile"
         class="do-not-fade"
         *ngIf="sessionService.isLogged() && mostrarToolBar">
         <mat-icon aria-label="Side nav profile icon">{{sessionMinutesLeft < 5 ? 'warning' : 'person'}}</mat-icon>
         {{profileName}} {{profileClock}}
     </a>-->
    <!--    <button-->
    <!--        type="button"-->
    <!--        aria-label="Logout"-->
    <!--        class="logout"-->
    <!--        mat-icon-button-->
    <!--        matTooltip="Logout"-->
    <!--        (click)="logout()"-->
    <!--        *ngIf="sessionService.isLogged()">-->
    <!--        <mat-icon aria-label="Side nav logout icon">meeting_room</mat-icon>-->
    <!--    </button>-->
    <!-- <a
         aria-label="Login"
         mat-icon-button
         matTooltip="Login"
         routerLink="login"
         *ngIf="!sessionService.isLogged() && mostrarToolBar">
         <mat-icon aria-label="Side nav login icon">person</mat-icon>
     </a>-->
</mat-toolbar>
