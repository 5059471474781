<div class="branding" [routerLink]="['/inicio']">
    <img src="assets/Logo_azul.svg" alt="" class="app-logo">
    <span class="app-logo-text">CNCDENTAL</span>

    <span style="margin: auto"></span>
    <!--    <div-->
    <!--            class="sidebar-compact-switch"-->
    <!--            [ngClass]="{active: layoutConf.sidebarCompactToggle}"-->
    <!--            (click)="toggleCollapse()">-->
    <!--        <span></span></div>-->
</div>

<!-- Sidebar user -->
<div class="app-user">
    <div class="app-user-photo">
        <!--        <img src="assets/images/" class="mat-elevation-z1" alt="">-->
        <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
    </div>
    <span class="app-user-name mb-8">
        {{ profileName }}</span>
    <!-- Small buttons -->
    <div class="app-user-controls">
        <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Inicio"
                routerLink="/inicio">
            <mat-icon>home</mat-icon>
        </button>
        <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Datos Generales"
                routerLink="/datos">
            <mat-icon>contact_page</mat-icon>
        </button>
        <!--        <button-->
        <!--                class="text-muted"-->
        <!--                mat-icon-button-->
        <!--                mat-xs-button-->
        <!--                matTooltip="Inbox"-->
        <!--                routerLink="/inbox">-->
        <!--            <mat-icon>email</mat-icon>-->
        <!--        </button>-->
        <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Cerrar sesión"
                (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
        </button>
    </div>
</div>

