import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Archivo} from "./archivo.model";

@BasicEntity({
    name: 'Archivo imagen',
    endpoint: 'archivo_imagen_solicituds',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    type: "ArchivoImagenSolicitud"
})
export class ArchivoImagenSolicitud extends Archivo {

    public static readonly SOLICITUD_MAP: PropertyMap = {
        name: 'Solicitud',
        type: PropertyType.FutureUri('solicitud.model.ts'),
        readWrite: ReadWrite.ReadOnly
    }
    @BasicProperty(ArchivoImagenSolicitud.SOLICITUD_MAP)
    private _solicitud;

    get solicitud() {
        return this._solicitud;
    }

    set solicitud(value) {
        this._solicitud = value;
    }
}
