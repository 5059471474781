import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Empleado} from "../personas/empleado.model";
import {UserGroup} from "../personas/user-group.model";
import {IncidenciaAccion} from "./incidencia-accion.model";
import {IncidenciaPlantilla} from "./incidencia-plantilla.model";
import {Usuario} from "../personas/usuario.model";
import {BaseEntity} from "../base-entity.model";
import {FORMATS} from "../../basic-entity-front/const";
import {Cliente} from "../personas/cliente.model";

@BasicEntity({
    name: 'Incidencias',
    endpoint: 'incidencias',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'descripcion',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'Incidencia'
})
export class Incidencia extends BaseEntity {
    static readonly ESTADOS_INCIDENCIA = {
        PENDIENTE: 'Pendiente',
        EN_CURSO: "En curso",
        RESUELTA: "Resuelta",
        DESCARTADA: "Descartada"
    };

    static readonly PRIORIDAD_INCIDENCIA = {
        URGENTE: 'Urgente',
        ALTA: "Alta",
        NORMAL: "Normal",
        BAJA: "Baja"
    };

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Descripción de la incidencia',
        type: PropertyType.String(),
        nullable: true
    })
    private _descripcion;

    @BasicProperty({
        name: 'Observaciones de la incidencia',
        type: PropertyType.String(),
        nullable: true
    })
    private _observaciones;

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(Incidencia.ESTADOS_INCIDENCIA)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _estado;

    @BasicProperty({
        name: 'Prioridad',
        type: PropertyType.Choice(...Object.values(Incidencia.PRIORIDAD_INCIDENCIA)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _prioridad;

    @BasicProperty({
        name: 'Tipo de incidencia',
        type: PropertyType.NestedModel(IncidenciaPlantilla, null, NestedBehavior.SendIriOnly)
    })
    private _tipoIncidencia;

    @BasicProperty({
        name: 'Asignada a',
        type: PropertyType.NestedModel(Empleado, null, NestedBehavior.SendIriOnly),
        nullable: true,
        filters: [ExactSearchFilter]
    })
    private _asignadaA;

    @BasicProperty({
        name: 'Asignada a',
        type: PropertyType.NestedModel(Usuario, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _asignadaAUsuario;

    @BasicProperty({
        name: 'Departamento afectado',
        type: PropertyType.NestedModel(UserGroup, null, NestedBehavior.SendIriOnly),
        nullable: true,
        filters: [ExactSearchFilter]
    })
    private _departamentoAfectado;

    @BasicProperty({
        name: 'Cliente afectado',
        type: PropertyType.NestedModel(Cliente, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _clienteAfectado;

    @BasicProperty({
        name: 'Es pública',
        type: PropertyType.Boolean()
    })
    private _publica = false;

    @BasicProperty({
        name: 'Afectado',
        type: PropertyType.String(),
        array: true
    })
    private _irisAfectados = [];

    @BasicProperty({
        name: 'Afectado',
        type: PropertyType.Object(),
        array: true
    })
    private _objetosAfectados = [];

    @BasicProperty({
        name: 'Acciones',
        type: PropertyType.NestedModel(IncidenciaAccion, 'incidencia', NestedBehavior.SendIriOnly),
        array: true
    })
    private _acciones;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }

    get observaciones() {
        return this._observaciones;
    }

    set observaciones(value) {
        this._observaciones = value;
    }

    get estado() {
        return this._estado;
    }

    set estado(value) {
        this._estado = value;
    }

    get prioridad() {
        return this._prioridad;
    }

    set prioridad(value) {
        this._prioridad = value;
    }

    get tipoIncidencia() {
        return this._tipoIncidencia;
    }

    set tipoIncidencia(value) {
        this._tipoIncidencia = value;
    }

    get asignadaA() {
        return this._asignadaA;
    }

    set asignadaA(value) {
        this._asignadaA = value;
    }

    get departamentoAfectado() {
        return this._departamentoAfectado;
    }

    set departamentoAfectado(value) {
        this._departamentoAfectado = value;
    }

    get irisAfectados() {
        return this._irisAfectados;
    }

    set irisAfectados(value) {
        this._irisAfectados = value;
    }

    get acciones() {
        return this._acciones;
    }

    set acciones(value) {
        this._acciones = value;
    }

    get asignadaAUsuario() {
        return this._asignadaAUsuario;
    }

    set asignadaAUsuario(value) {
        this._asignadaAUsuario = value;
    }

    get objetosAfectados(): any[] {
        return this._objetosAfectados;
    }

    set objetosAfectados(value: any[]) {
        this._objetosAfectados = value;
    }

    get clienteAfectado() {
        return this._clienteAfectado;
    }

    set clienteAfectado(value) {
        this._clienteAfectado = value;
    }

    get publica(): boolean {
        return this._publica;
    }

    set publica(value: boolean) {
        this._publica = value;
    }
}

IncidenciaAccion.MAP_INCIDENCIA.type = PropertyType.Uri(Incidencia);
