import {Component, OnDestroy, OnInit} from '@angular/core';
import {Widget} from "../widget";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {SolicitudIntraoral} from "../../../model/solicitudes/solicitud-intraoral.model";
import {Solicitud} from "../../../model/solicitudes/solicitud.model";
import {SolicitudEscaneo} from "../../../model/solicitudes/solicitud-escaneo.model";
import {FilterAndData, FilterList} from "../../../api/filter-list";
import {ExactSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-solicitudes-rechazadas-widget',
    templateUrl: './solicitudes-rechazadas-widget.component.html',
    styleUrls: ['./solicitudes-rechazadas-widget.component.scss']
})
export class SolicitudesRechazadasWidgetComponent implements Widget, OnInit, OnDestroy {
    public static minCols = 1;
    public static minRows = 2;
    public static maxRows = 2;
    public static maxCols = 1;
    public static widgetName = 'Solicitudes rechazadas';
    public static widgetKey = 'solicitudes-rechazadas';
    numeroSolicitudes: number = 0;
    private _interval: any;
    private _intervalSeconds = 10;

    constructor(private interfaceProvider: InterfaceProviderService) {
        this.execute();
    }

    ngOnInit(): void {
        this._interval = setInterval(
            () => this.execute(),
            this._intervalSeconds * 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this._interval);
    }

    private execute() {
        this.numeroSolicitudes = 0;

        const estadoProp = this.interfaceProvider.interfaceForModel(SolicitudEscaneo).mappingModelToApi['estado'];
        const filters: FilterList = [new FilterAndData(ExactSearchFilter, [Solicitud.ESTADOS_SOLICITUD.RECHAZADO], estadoProp)];
        this.interfaceProvider.managerForModel(SolicitudEscaneo).loader.findAndFollow(filters).pipe(map(r => {
            return r.member;
        })).subscribe(ps => {
            this.numeroSolicitudes += ps.length;
        })

        const estadoProp2 = this.interfaceProvider.interfaceForModel(SolicitudIntraoral).mappingModelToApi['estado'];
        const filters2: FilterList = [new FilterAndData(ExactSearchFilter, [Solicitud.ESTADOS_SOLICITUD.RECHAZADO], estadoProp2)];
        this.interfaceProvider.managerForModel(SolicitudIntraoral).loader.findAndFollow(filters2).pipe(map(r => {
            return r.member;
        })).subscribe(ps => {
            this.numeroSolicitudes += ps.length;
        })
    }
}


